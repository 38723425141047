import { Card, Text, IndexTable, Link, Page, useIndexResourceState, Toast, Thumbnail, Button, Tooltip, InlineStack, TextField } from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react"
import {
  NoteIcon,
  PlusIcon,
  EditIcon,
  CheckIcon
} from '@shopify/polaris-icons';
import {
  clearError,
  getEntitiesByStock,
  changeQuantityEntityToStock
} from "store/product.store.reducer";
import {
  getEntity,
} from "store/stock.store.reducer";
import { useAppDispatch, useAppSelector } from "config/store";
import helpers from "../../helpers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Pagination from "components/pagination";
import SkeletonLoading from 'components/skeletonLoading';
import __ from 'languages/index';
import SimpleFilter from 'components/simpleFilter';
import { validateNumber } from "utils";

export default function StockDetail() {
  const entity = useAppSelector((state) => state.stock.entity);
  const entities = useAppSelector((state) => state.product.entities);
  const loading = useAppSelector((state) => state.product.loading);
  const totalItems = useAppSelector((state) => state.product.totalItems);
  const updating = useAppSelector((state) => state.product.updating);
  const updateSuccess = useAppSelector((state) => state.product.updateSuccess);
  const [isEdit, setIsEdit] = useState(false);
  const [isProductChecked, setProductChecked] = useState('');
  const [quantity, setQuantity] = useState('');
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const [isError, setIsError] = useState(false)
  const account = useAppSelector(state => state.user.account);

  const toggleActive = useCallback(() => {
    dispatch(clearError());
  }, []);

  const toastSuccessMarkup = updateSuccess ? (
    <Toast content={'Cập nhật thành công'} onDismiss={toggleActive} />
  ) : null;

  // get stock_id
  let { stock_id } = useParams()
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery: any = helpers.ExtractUrl(useParam.search) || false;

  const initialQuery = {
    query: "",
    page: 1,
    limit: 25,
    sort: "createdAt:desc",
  };

  const [mainQuery, setMainQuery] = useState({
    ...StringQuery,
    ...initialQuery,
    stock_id: `${stock_id}`
  });

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({ ...mainQuery, page: numPage, limit: limit });
  }, [mainQuery]);

  let m = mainQuery;
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      m = initialQuery;
      return setMainQuery(initialQuery);
    } else {
      m = Object.assign({}, m, _value);
      setMainQuery(m);
    }
  }, []);


  /**
   * Lòng và lòng vòng, cuối cùng là để nó không gọi quá nhiều lần ...
   */
  const reduceLoad = useCallback((_mainQuery) => {
    dispatch(getEntitiesByStock(_mainQuery));
  }, []);

  useEffect(()=> {
    updateSuccess && reducerLoading(mainQuery);
  },[updateSuccess])

  const reducerLoading = useMemo(() => helpers.debounce(_value => reduceLoad?.call(this, _value), 900), []);

  useEffect(() => {
    reducerLoading(mainQuery);
  }, [mainQuery]);

  useEffect(() => {
    dispatch(getEntity(stock_id));
  }, [stock_id]);


  function getProductDetail(product_id: string) {
    history("/product/edit/" + product_id);
    return;
  }

  const resourceName = {
    singular: "product",
    plural: "sản phẩm",
  };
  const resourceIDResolver = (products) => {
    return products.product_id;
  };
  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(entities ?? [], {
    resourceIDResolver,
  });

  const handleEditQuantity = (product_id: string) => {
    const dataProductAdd = {
      product_id: product_id,
      stock_id: stock_id,
      product_quantity: +quantity
    }
    dispatch(changeQuantityEntityToStock(dataProductAdd)).then(() =>{
      setQuantity('')
      setIsEdit(false)
    })
  }

  const onChangeQuantity = (value: string) => {
    setQuantity(value)
    if (!validateNumber(value)){
      setIsError(true)
    }else{
      setIsError(false)
    }
  }

  const rowMarkup = entities?.map(
    (
      {
        product_id,
        product_media,
        product_name,
        product_price,
        product_to_stock
      },
      index
    ) => (
      <IndexTable.Row
        id={product_id}
        key={product_id}
        selected={selectedResources.includes(product_id)}
        position={index}
      >
        <IndexTable.Cell><Thumbnail size="small" source={Array.isArray(product_media) && product_media.length ? product_media[0] : NoteIcon} alt="" /></IndexTable.Cell>
        <IndexTable.Cell><Link removeUnderline onClick={() => getProductDetail(product_id)}>{product_name}</Link></IndexTable.Cell>
        <IndexTable.Cell><Text as="span" variant="bodyMd">{helpers.formatNumber(product_price, ".")} vnđ</Text></IndexTable.Cell>
        <IndexTable.Cell>
         {
           (isEdit && isProductChecked === product_id) ? <TextField
           label=''
           placeholder="Số lượng sản phẩm"
           value={quantity}
           onChange={(e) => onChangeQuantity(e)}
           autoComplete="off"
           error={isError && "Giá trị không đúng"}
         /> :
         product_to_stock.find(item => item.stock_id === stock_id)?.product_quantity || 0
         }
        </IndexTable.Cell>
        {(['admin', 'stock_manager'].includes(account?.user_role)) &&
        <IndexTable.Cell>
          <InlineStack gap={"200"} align="start" blockAlign="center">
            <Tooltip content="Sửa số lượng sản phẩm trong kho">
              {
                (isEdit && isProductChecked === product_id) ? 
                <Button disabled={isError} variant="tertiary" onClick={() => handleEditQuantity(product_id)} icon={CheckIcon} /> :
                <Button variant="tertiary" onClick={() => { 
                  setIsEdit(true)  
                  setIsError(false)
                  setProductChecked(product_id)
                  setQuantity(`${product_to_stock.find(item => item.stock_id === stock_id)?.product_quantity}` || '')
                }} icon={EditIcon} />
              }
            </Tooltip>
          </InlineStack>
        </IndexTable.Cell>
      }
      </IndexTable.Row>
    )
  );

  return (
    <>
      {entities === null ? <SkeletonLoading fullWidth /> :
        (
          <Page
            fullWidth
            title={`Danh sách sản phẩm kho hàng: ${entity?.stock_name}`}
            backAction={{
              url: '/stock'
             }}
            primaryAction={(['admin', 'stock_manager'].includes(account?.user_role)) && <Button icon={PlusIcon} variant="primary" onClick={() => history(`/stock/add_product/${stock_id}`, { state: { stockName: entity?.stock_name, currentPath:  useParam.pathname} })}>Thêm sản phẩm vào kho</Button>}
          >
            <Card padding="0">
              <SimpleFilter loading={loading}
                onCallback={filterCallback}
                options={[
                  // { label: 'Hết hàng', value: '0', field: 'product_meta.product_in_stock_quantity' },
                  // { label: 'Ngừng bán', value: '0', field: 'product_status' },
                  // { label: 'Đang bán', value: '1', field: 'product_status' },
                ]}
                sortField={[
                  // { label: 'Giá sản phẩm', field: 'product_price' },
                  // { label: 'Tồn kho', field: 'product_quantity' },
                  // { label: 'Đã bán', field: 'product_sold_quantity' },
                ]}
                totalItems={totalItems}
              />

              <IndexTable
                resourceName={resourceName}
                itemCount={entities?.length || 0}
                selectedItemsCount={
                  allResourcesSelected ? "All" : selectedResources.length
                }
                selectable={false}
                onSelectionChange={handleSelectionChange}
                bulkActions={[]}
                onSort={null}
                loading={updating}
                sortable={[]}
                headings={[
                  { title: "" },
                  { title: "Sản phẩm" },
                  { title: "Giá bán" },
                  { title: "Số lượng trong kho" },
                  (['admin', 'stock_manager'].includes(account?.user_role)) && { title: "Hành động" },
                ]}
              >
                {entities && rowMarkup}
              </IndexTable>
            </Card>

            <br />
            {totalItems > mainQuery.limit ? (
              <Pagination
                TotalRecord={totalItems}
                onChange={onChangePagination}
                pageSize={Number(mainQuery.limit)}
                activeCurrentPage={Number(mainQuery.page)}
              />
            ) : null}
          </Page>
        )}

      {toastSuccessMarkup}
    </>
  )
}