import { Page, Toast, Link, InlineStack, Layout, Card, Avatar, IndexTable, useIndexResourceState } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import dateandtime from 'date-and-time';
import { getEntities, clearError, deleteEntity, createEntities } from 'store/user_referrer.store.reducer';
import { useAppDispatch, useAppSelector } from "config/store";
import Pagination from "components/pagination";
import helpers from "helpers/index";
import __ from "languages/index";
import { useLocation, useNavigate } from "react-router-dom";
import SkeletonLoading from "components/skeletonLoading";
import ModalAssignUserReferrer from "./components/ModalAssignUserReferrer";

export default function UserReferrerList() {

  const loading = useAppSelector((state) => state.user_referrer.loading);
  const updating = useAppSelector((state) => state.user_referrer.updating);
  const updateSuccess = useAppSelector((state) => state.user_referrer.updateSuccess);
  const entities = useAppSelector((state) => state.user_referrer.entities);
  const [showModal, setShowModal] = useState(false)
  const totalItems = useAppSelector((state) => state.user_referrer.totalItems);
  const [dataChangeUserReferrer, setDataChangeUserReferrer] = useState(null)
  const [idUserReferrer, setIdUserReferrer] = useState('')

  const dispatch = useAppDispatch();
  const history = useNavigate();
  const location = useLocation();

  const rootSearchPath = window.location.search;
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;

  const initialQuery = {
    query: "",
    page: 1,
    limit: 40,
    sort: "createdAt:desc",
  };

  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery,
  });



  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({ ...mainQuery, page: numPage, limit: limit });
  }, [mainQuery]);

  const clearErrorCallback = useCallback(() => {
    dispatch(clearError());
  }, []);

  // const deleteStock = useCallback((stock_id) => {
  //     dispatch( deleteEntity(stock_id) );
  // }, []);

  // call api setting user refferrer
  const onSettingUserReferrer = () => {
    if (dataChangeUserReferrer) {
      const dataForm = {
        user_id: dataChangeUserReferrer?.user?.user_id,
        user_referrer: dataChangeUserReferrer?.userRefferrer?.user_id
      }
      dispatch(createEntities(dataForm)).then(() => {
        setShowModal(false)
        setDataChangeUserReferrer(null)
      })
    }
  }


  /** Clear Error */
  useEffect(() => {
    clearErrorCallback();
  }, []);

  // Delete user referrer call api
  const deleteUserReferrer = (id: string) => {
    if (window.confirm('Bạn có chắc chắn muốn xóa không ?')) {
      dispatch(deleteEntity({
        user_referrer_id: id
      }))
    }
  }

  // edit user referrenr
  const editUserReferrer = (user_data, user_referrer_data, ID) => {
    setDataChangeUserReferrer({
      user: user_data,
      userRefferrer: user_referrer_data
    })
    setIdUserReferrer(ID)
    setShowModal(true)
  }

  useEffect(() => {
    if(!showModal){
      setDataChangeUserReferrer(null)
      setIdUserReferrer('')
    }
  }, [showModal])

  const toastSuccessMarkup = updateSuccess ? (
    <Toast content={'Cập nhật thành công!'} onDismiss={clearErrorCallback} />
  ) : null;

  let m = mainQuery;
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      m = initialQuery;
      return setMainQuery(initialQuery);
    } else {
      m = Object.assign({}, m, _value);
      // setMainQuery(m);
    }
  }, []);

  useEffect(() => {
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);

  const resourceName = {
    singular: "người dùng",
    plural: "người dùng",
  };

  const resourceIDResolver = (products) => {
    return products.product_id;
  };
  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(entities ?? [], {
    resourceIDResolver,
  });


  const shortcutActions = (user_id: number | string, user_role: string) => {
    history(`/${user_role !== 'customer' ? 'user_internal' : 'customer'}/view/` + user_id, {
      state: {
        locationCurrent : location.pathname
      }
    });
  };


  const rowMarkup = entities?.map(
    (
      {
        ID,
        createdAt,
        user_referrer_data,
        user_data,
      },
      index
    ) => (
      <IndexTable.Row
        id={ID}
        key={ID}
        selected={selectedResources.includes(ID)}
        position={index}
      >
        <IndexTable.Cell>{ID}</IndexTable.Cell>
        <IndexTable.Cell>
          <InlineStack gap={"200"} align="start" blockAlign="center">
            <Avatar source={user_referrer_data?.user_avatar} name={user_referrer_data?.display_name} initials={user_referrer_data?.display_name?.charAt(0)} size="sm" />
            <Link
              key={user_referrer_data?.user_id}
              onClick={() => shortcutActions(user_referrer_data?.user_id,user_referrer_data?.user_role)}
            >
              {user_referrer_data?.display_name}
            </Link>
          </InlineStack>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <InlineStack gap={"200"} align="start" blockAlign="center">
            <Avatar source={user_data?.user_avatar} name={user_data?.display_name} initials={user_data?.display_name?.charAt(0)} size="sm" />
            <Link
              key={user_data?.user_id}
              onClick={() => shortcutActions(user_data?.user_id, user_data?.user_role)}
            >
              {user_data?.display_name}
            </Link>
          </InlineStack>
        </IndexTable.Cell>
        <IndexTable.Cell>{createdAt ? <span>{dateandtime.format(new Date(+createdAt), 'DD-MM-YYYY')}</span> : ''}</IndexTable.Cell>
        {/* <IndexTable.Cell>

          <InlineStack gap={"200"} align="start" blockAlign="center">
            <Tooltip content="Xoá dữ liệu giới thiệu">
              <Button variant="tertiary" icon={DeleteIcon} onClick={() => deleteUserReferrer(ID)} />
            </Tooltip>
            <Tooltip content="Sửa dữ liệu giới thiệu">
              <Button variant="tertiary" icon={EditIcon} onClick={() => editUserReferrer(user_data, user_referrer_data, ID)} />
            </Tooltip>
          </InlineStack>
        </IndexTable.Cell> */}
      </IndexTable.Row>
    )
  );

  return (
    <>
      {toastSuccessMarkup}
      {(loading || updating) ? <SkeletonLoading /> :
        <Page
          fullWidth
          title={'Giới thiệu người dùng'}
          // primaryAction={
          //   <Button
          //     icon={PlusIcon}
          //     variant="primary"
          //     onClick={() => {
          //       setShowModal(!showModal)
          //     }}>
          //     Tạo giới thiệu người dùng
          //   </Button>}
        >
          <Layout>
            <Layout.Section>
              <Card padding={'0'}>
                {/* <SimpleFilter loading={loading}
                  onCallback={filterCallback}
                  totalItems={totalItems}
                  options={[
                  ]}
                  sortField={[

                  ]}
                /> */}
                <IndexTable
                  resourceName={resourceName}
                  selectable= {false}
                  itemCount={entities?.length || 0}
                  selectedItemsCount={
                    allResourcesSelected ? "All" : selectedResources.length
                  }
                  onSelectionChange={handleSelectionChange}
                  bulkActions={[]}
                  onSort={null}
                  loading={updating}
                  sortable={[]}
                  headings={[
                    { title: "Mã" },
                    { title: "Thành viên giới thiệu" },
                    { title: "Thành viên được giới thiệu" },
                    { title: "Ngày giới thiệu" },
                    // { title: "Hành động" },
                  ]}
                >
                  {entities && rowMarkup}
                </IndexTable>
              </Card>
              <br />
              {totalItems > mainQuery.limit ? (
                <Pagination
                  TotalRecord={totalItems}
                  activeCurrentPage={mainQuery.page}
                  pageSize={mainQuery.limit}
                  onChange={onChangePagination}
                />
              ) : null}
            </Layout.Section>
          </Layout>
        </Page>
      }
      <ModalAssignUserReferrer
        show={showModal}
        closeBtn={() => setShowModal(false)}
        setDataChangeUserReferrer={setDataChangeUserReferrer}
        dataChangeUserReferrer={dataChangeUserReferrer}
        onSettingUserReferrer={onSettingUserReferrer}
        idUserReferrer={idUserReferrer}
      />
    </>
  )
}