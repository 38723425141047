import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { IQueryParams, createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { TypedProduct } from '../interface/product.model';
import { ENDPOINT_URL } from 'constant';
import { TypeCommisionData } from 'interface/commission.model';

/**
*   Reducer used for front-end, with media.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using media.model.ts
*/

type typedUpdateManyEntry = {
  product_id: string[],
  product_status: string
}

interface getQuery extends IQueryParams, TypedProduct { };

const initialState = {
  loading: false,
  errorMessage: null,
  entities: null as any as TypedProduct[],
  entity: null as TypedProduct,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};
const apiUrl = 'v2/product';

// Actions

export const getEntities = createAsyncThunk('product/fetch_entity_list', async (object: getQuery) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}/admin${EndURL}`;
  return axios.get<TypedProduct>(requestUrl);
});

/**
 * Upload
 * loaded = 1 mean 100%
 * abord, do not use
 */

export const uploadFile = createAsyncThunk(
  'product/upload_entity',
  async (urldata: any) => {
    const requestUrl = `${apiUrl}/upload`;
    return axios.post<any>(requestUrl, urldata, {
      onUploadProgress: (progressEvent: any) => {
        //updateProgress = Math.floor(progressEvent.loaded / progressEvent.total) * 100;
      }
    });
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  'product/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<TypedProduct>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'product/create_entity',
  async (entity: any, thunkAPI) => {
    const result = await axios.post<TypedProduct>(`${apiUrl}`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'product/update_entity',
  async (entity: any, thunkAPI) => {
    let productID = String(entity.product_id);
    delete entity.product_id;
    const result = await axios.patch<TypedProduct>(`${apiUrl}/${productID}`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntity(productID));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntities = createAsyncThunk(
  'product/update_entities',
  async (entity: typedUpdateManyEntry) => {
    return await axios.patch<any>(`${apiUrl}`, helpers.cleanEntity(entity));
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'product/partial_update_entity',
  async (entity: TypedProduct, thunkAPI) => {
    let mediaID = String(entity.product_id);
    delete entity.product_id;
    const result = await axios.patch<TypedProduct>(`${apiUrl}/${mediaID}`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntity(mediaID));
    return result;
  },
  { serializeError: serializeAxiosError }
);


export const deleteEntity = createAsyncThunk(
  'product/delete_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return await axios.delete<any>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

// Call api product with stock
export const getEntitiesByStock = createAsyncThunk('product/fetch_entity_list_by_stock', async (object?: getQuery) => {
  const EndURL = helpers.buildEndUrl(object);
  const EndURLConvert = EndURL.replace('stock_id', `product_to_stock.stock_id`);
  const requestUrl = `${apiUrl}${EndURLConvert}`;
  return axios.get<TypedProduct>(requestUrl);
});

export const addEntityToStock = createAsyncThunk(
  'product/add_entity_to_stock',
  async (entity: any, thunkAPI) => {
    const result = await axios.post<TypedProduct>(`${ENDPOINT_URL}/product_to_stock`, helpers.cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const changeQuantityEntityToStock = createAsyncThunk(
  'product/change_quantity_entity_to_stock',
  async (entity: any, thunkAPI) => {
    const result = await axios.patch<TypedProduct>(`${ENDPOINT_URL}/product_to_stock`, helpers.cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// add product to commission
export const addProductToCommission = createAsyncThunk(
  'product/add_product_to_commission',
  async (entity: any, thunkAPI) => {
    const dataForm = {
      commission_id: entity.commission_id,
      product_id: entity.product_id,
    }
    const mainQuery = entity?.mainQuery ? entity?.mainQuery  : {}
    const result = await axios.post<TypeCommisionData>(`${ENDPOINT_URL}/product_to_commission`, helpers.cleanEntity(dataForm));
    thunkAPI.dispatch(getEntities(mainQuery));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const Reducer_Product = createEntitySlice({
  name: 'product',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
      state.updateSuccess = false;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
      })
      .addCase(deleteEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = "Can not delete this Entity"
      })
      .addCase(addEntityToStock.fulfilled, (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addCase(addEntityToStock.pending, (state, action) => {
        state.updating = true;
        state.loading = true;
        state.updateSuccess = false;
      })
      .addCase(addEntityToStock.rejected, (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = false;
      })
      .addCase(addProductToCommission.fulfilled, (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addCase(addProductToCommission.pending, (state, action) => {
        state.updating = true;
        state.loading = true;
        state.updateSuccess = false;
      })
      .addCase(addProductToCommission.rejected, (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = false;
      })
      .addCase(changeQuantityEntityToStock.fulfilled, (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addCase(changeQuantityEntityToStock.pending, (state, action) => {
        state.updating = true;
        state.loading = true;
        state.updateSuccess = false;
      })
      .addCase(changeQuantityEntityToStock.rejected, (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = false;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: (action.payload as any).data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getEntitiesByStock), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: (action.payload as any).data,
          entity: null,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(deleteEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = null;
      })
      .addMatcher(isPending(deleteEntity), state => {
        state.updating = true;
        state.loading = true;
        state.updateSuccess = false;
        state.entity = null;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, updateEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isRejected(updateEntities), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.errorMessage = action.payload
      })
      .addMatcher(isFulfilled(updateEntities), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      });

  },
});

export const { clearError, reset } = Reducer_Product.actions;

// Reducer
export default Reducer_Product.reducer;
