import { Autocomplete, Avatar, Icon, Select } from "@shopify/polaris";
import { useAppDispatch, useAppSelector } from "config/store";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {USER_CAPACITY_LIST} from 'constant';
import __ from 'languages/index';
import {
    searchUser
  } from 'store/user.store.reducer';
  
  import {
    SearchIcon
  } from "@shopify/polaris-icons";
import helpers from "helpers/index";
import { IUser } from "interface/user.model";
import {PlusCircleIcon} from '@shopify/polaris-icons';

type TypedSearchUser = {
  current_user_id?: bigint | string;
  onClose?: (val: IUser) => void;
  title: string;
  placeholder?: string;
  user_role?: "user" | "customer" | "sale" | "admin" | "visitor";
  disabled?: boolean;
  support_create_new?: boolean,
  support_filter?: boolean,
}

/**
 * 
 * @param current_user_id bigint
 * @param  onClose callback with bigint userid
 * @returns 
 */
export default function QuickSearchUser({ disabled, current_user_id = null, user_role = null, onClose, title, placeholder = 'Tìm một người dùng', support_create_new=false, support_filter=false}: TypedSearchUser) {

/**!SECTION
 * Select người handler
 */
    const dispatch = useAppDispatch();

    /**
     * User list
     */
    const userLoading = useAppSelector((state) => state.user.loading);  
    const userList = useAppSelector((state) => state.user.entities);  


  /**
   * Assign cho ai đóa
   */
  const [userSelected, setUserSelected] = useState<string[]>([]);
  const [userQuickSearchText, setUserQuickSearchText] = useState<string>('');
  const [userListForQuickOption, setUserListForQuickOption] = useState([]);

  const [userRoleSelect, setUserRoleSelect] = useState<string>(user_role);


  const onQueryToServer = useCallback( (keyword: string, userRoleSelect: string) => {
    dispatch(searchUser({
      query: keyword,
      user_role: userRoleSelect || undefined,
      sort: 'createdAt:desc',
      limit: 5
    }));
  }, []);


useEffect( () => {
  onQueryToServer('', userRoleSelect);
}, [userRoleSelect]);


useEffect( () => {
  let innerUser = [];
  if ( userList )
    for ( let user of userList ) {
      let display_name = user.display_name || user.user_login || user.user_phonenumber;
      innerUser.push({
        value: user.user_id,
        label: user.display_name,
        media: <Avatar source={user.user_avatar} name={display_name} initials={display_name[0]} />
      });

      if ( user.user_id === current_user_id ) {
        setUserQuickSearchText(user.display_name);
      }
    }
    setUserListForQuickOption(innerUser);
}, [userList, current_user_id]);


const onChangeMainQueryCallback = useMemo(() => {
  return helpers.debounce((_value: string, userRoleSelect: string) => {
    onQueryToServer?.call(this, _value, userRoleSelect)
  }, 400);
}, []);

const updateUserText = useCallback(
  (value: string) => {
    setUserQuickSearchText(value);
    onChangeMainQueryCallback(value, userRoleSelect);
  },
  [userRoleSelect],
);

const findUserSelectedInEntities = useCallback( (user_id) => {
  if ( Array.isArray(userList) ) {
    let a = userList.filter( a => a.user_id === user_id );
    return a[0] || null
  } 
}, [userList]);


const updateSelection = useCallback(
  (selected: string[]) => {
    const selectedValue = selected.map((selectedItem) => {
      const matchedOption = userListForQuickOption.find((option) => {
        return option.value.match(selectedItem);
      });
      return matchedOption && matchedOption.label;
    });    
    setUserSelected(selected);
    setUserQuickSearchText(selectedValue[0] || '');
    // @ts-ignore
    onClose?.call(this, findUserSelectedInEntities(selected[0]) );
  },
  [userListForQuickOption]
);

const [showNewCustomerModal, setShowNewCustomerModal] = useState(false);
const closeNewCustomerModal = useCallback( (userData?: IUser) => {
  setShowNewCustomerModal(false);
  if ( userData ) {
    setUserQuickSearchText(String(userData.display_name || userData.user_email || userData.user_login ));
    setUserSelected([String(userData.user_id)]);
    onClose?.call(this, userData); // ...
  } 
}, []);

const textFieldSelectUser = (
  <>
    <Autocomplete.TextField
        onChange={updateUserText}
        label={title}
        disabled={disabled}
        value={userQuickSearchText}
        prefix={<Icon source={SearchIcon} tone="base" />}    
        placeholder={placeholder}
        autoComplete="off"
    />
  </>
);

  const handleSelectChange = useCallback( (value: string) => {
    setUserRoleSelect(value);
  }, []);


    const FilterCustomerType = useCallback( () => {
        return (
        <>
          <Select
            label="Lọc vai trò:"
            labelInline
            options={ [ ...[{label: 'Tất cả', value: ''}], ...USER_CAPACITY_LIST.map( (item) => {
                return {
                  label: __(item),
                  value: item,
                }
              })
            ]}
            onChange={handleSelectChange}
            value={userRoleSelect || undefined}
          />
          <br />
        </>
        )
    }, [userRoleSelect]);

    return (
        <>
          {
            support_filter && <FilterCustomerType />
          }
            <Autocomplete
                actionBefore={ support_create_new && {
                  accessibilityLabel: 'Tạo người dùng mới',
                  badge: {
                    tone: 'new',
                    content: 'New!',
                  },
                  content: 'Tạo người dùng mới',
                  ellipsis: true,
                  icon: PlusCircleIcon,
                  onAction: () => {
                    setShowNewCustomerModal(true);
                    return false;
                  },
                }}
              options={userListForQuickOption}
              selected={userSelected}
              onSelect={updateSelection}
              textField={textFieldSelectUser}
              loading={userLoading}
            />
        </>
    )
}