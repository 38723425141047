import { Box, Button, Spinner, Text } from "@shopify/polaris";
import { Field } from "@shopify/react-form";
import __ from "languages/index";
import { useEffect, useRef, useState } from "react";
import {
    UploadIcon,
    XCircleIcon,
    DragDropIcon
} from '@shopify/polaris-icons';
import 'media/css/product.scss'
import { uploadFile } from "../services/uploadFile";
import { TypedMediaResponse } from "interface/media.model";
import ImgsViewer from 'react-images-viewer'
import ModalCropImage from "components/modalCropImage";
import { dataURItoBlob } from "utils";
interface TypedProps {
    fieldUrl: Field<string[]>;
    onError: (err: any) => void;
}


const ProductUpload = (props: TypedProps) => {
    const { fieldUrl, onError } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [showCrop, setShowCrop] = useState(false);
    const [imgCrop, setImgCrop] = useState("");
    const [currImg, setCurrImg] = useState(0);
    const [loadingPercent, setLoadingPercent] = useState(0);
    const inputRef = useRef(null);
    const [images, setImages] = useState<string[]>(fieldUrl.value || []);
    const [selected, setSelected] = useState({ item: '', index: 0 });
    const [loadingUpload, setLoadingUpload] = useState(false);

    function uploadSuccess(rep: TypedMediaResponse[]) {
        if (Array.isArray(rep)) {
            let srcList = [...rep.map((res) => res.src), ...fieldUrl.value];

            if (srcList?.length < 20) {
                srcList = srcList.slice(0, 20);
            }
            setLoadingUpload(false);
            fieldUrl.onChange(srcList);
        }
    }

    function uploadFail(rep: any) {
        onError?.call(this, rep);
        setLoadingUpload(false);
    }

    async function saveToServer(file: File[]) {
        try {
            const fd = new FormData();
            let fileListUrl = [];
            let invalidFile = false;
            Array.from(file).forEach((file) => {
                const allowedTypes = ["image/jpeg", "image/png"];
                if (allowedTypes.includes(file.type)) {
                    fd.append("file[]", file);
                    fileListUrl.push(URL.createObjectURL(file));
                } else {
                    invalidFile = true;
                }
            });
            if (invalidFile) throw "File không hợp lệ";

            const uploaded = await uploadFile(fd, setLoadingPercent);
            if (Array.isArray(uploaded)) {
                uploadSuccess(uploaded);
            }
        } catch (_) {
            uploadFail(_);
        }
    }

    useEffect(() => {
        setImages(fieldUrl.value)
    }, [fieldUrl])

    useEffect(() => {
        imgCrop && handleFileChange({});
    }, [imgCrop]);

    const handleFileChange = (e) => {
        if (imgCrop) {
            const file: any = dataURItoBlob(imgCrop);
            setLoadingUpload(true);
            saveToServer([file]);
        } else {
            const file = e.target.files;
            if (file?.length <= 0) return;
            setLoadingUpload(true);
            saveToServer(file);
        }
    };

    const handleRemoveFile = (indexImg) => {
        const filter = images.filter((_, index) => index !== indexImg);
        setImages(filter);
        fieldUrl.onChange(filter);
    };

    // Drag drop images product
    const handleDragStart = (e, index) => {
        e.dataTransfer.setData("index", index);
    };

    const handleDrop = (e, targetKey) => {
        e.preventDefault();
        const fromIndex = Number(e.dataTransfer.getData("index"));
        const toIndex = images.findIndex((_, index) => `image-${index}` === targetKey);

        if (toIndex !== fromIndex) {
            const draggedImage = images[fromIndex];
            const updatedImages = [...images];
            updatedImages.splice(fromIndex, 1);
            updatedImages.splice(toIndex, 0, draggedImage);
            setImages(updatedImages);
            fieldUrl.onChange(updatedImages);
        }
    };

    return !images.length ? (
        <div className="box_image">
            <Button onClick={() => inputRef.current.click()} size="large">
                {__("upload_image_product")}
            </Button>
            <input ref={inputRef} multiple type="file" hidden onChange={handleFileChange} />
            <Text as="p">{__("format_image_text")}</Text>
        </div>
    ) : (
        <Box minHeight="26.75rem" padding={"600"} borderRadius="200" background="bg-surface">
            <Text as="p">Đã tải lên {images.length} ảnh</Text>
            <div className="box_list_image">
                {
                    images.length < 20 && (
                        <div className="btn_upload" onClick={() => inputRef.current.click()} >
                            <UploadIcon width={'1.25rem'} />
                            <input ref={inputRef} multiple type="file" hidden onChange={handleFileChange} />
                        </div>
                    )
                }
                {[loadingUpload && (<div className="btn_upload">
                    <Spinner accessibilityLabel="Small spinner example" size="small" />
                </div>), ...images.map((item, index) => (
                    <div key={`image-${index}`} className="img_product" onDrop={(e) => handleDrop(e, `image-${index}`)} onDragOver={(e) => e.preventDefault()} draggable onDragStart={(e) => handleDragStart(e, index)}>
                        <img src={item} onClick={(e) => {
                            setIsOpen(true);
                            setCurrImg(0);
                        }} />
                        <Button onClick={() => handleRemoveFile(index)} icon={XCircleIcon} accessibilityLabel="Add theme" />
                        <div className="icon_crop">
                            <Button icon={DragDropIcon} onClick={() => {
                                setShowCrop(true);
                                setSelected({ item, index });
                            }} accessibilityLabel="Add theme" />
                        </div>
                    </div>
                ))]}
            </div>
            <ImgsViewer
                imgs={images.map((item) => ({ src: item }))}
                currImg={currImg}
                showThumbnails={true}
                isOpen={isOpen}
                onClickPrev={(e) => setCurrImg(currImg - 1)}
                onClickNext={(e) => setCurrImg(currImg + 1)}
                onClickThumbnail={(index) => setCurrImg(index)}
                onClose={(e) => setIsOpen(false)}
            />
            <ModalCropImage imageUrl={selected?.item} isOpen={showCrop} onClose={() => setShowCrop(false)} setImgCrop={setImgCrop} />
        </Box>
    );
};

export default ProductUpload;