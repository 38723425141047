import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, LegacyCard, ResourceItem, ResourceList, Select, SelectOption, Text, TextField, Thumbnail } from "@shopify/polaris";
import { BANNER_META_TYPE } from "constant";
import { Field } from "@shopify/react-form";
import { useAppDispatch, useAppSelector } from "config/store";
import { getCategories } from "store/product_category.store.reducer";
import { getEntities, getEntity } from "store/product.store.reducer";
import __helpers from "helpers/index";
import { NoteIcon } from '@shopify/polaris-icons';

interface TypeProps {
  bannerMetaType?: string;
  fieldBannerMetaProduct: Field<string>;
  fieldBannerMetaCategory: Field<string>;
  fieldBannerMetaLink: Field<string>;
}

function BannerMetaInfomation(props: TypeProps) {
  const { bannerMetaType, fieldBannerMetaProduct, fieldBannerMetaCategory, fieldBannerMetaLink } = props;
  const [renderedComponent, setRenderedComponent] = useState<React.ReactNode>(null);
  const [optionCategories, setOptionCategories] = useState<SelectOption[]>([])
  const [value, setValue] = useState('');
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const loading = useAppSelector(state => state.member.loading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCategories({})).unwrap().then((res) => {
      if (Array.isArray(res.data)) {
        const data = res.data.map(i => ({
          value: i.category_id,
          label: i.category_name
        }))
        setOptionCategories([{ value: '', label: '' }, ...data]);
      }
    }).catch((e) => {
      console.log("E125 >>>>", e);
    });

    dispatch(getEntities({})).unwrap().then((res) => {
      if (Array.isArray(res.data)) {
        setProducts(res.data)
      }
    }).catch((e) => {
      console.log("E125 >>>>", e);
    });

  }, []);

  const handleSearchDebounce = useMemo(() => {
    return __helpers.debounce((text: string) => {
      dispatch(getEntities({
        query: text
      })).unwrap().then((res) => {
        if (Array.isArray(res.data)) {
          setProducts(res.data)
        }
      }).catch((e) => {
        console.log("E125 >>>>", e);
      });

    }, 500)
  }, [])

  const handleChange = useCallback(
    (newValue: string) => {
      setValue(newValue)
      handleSearchDebounce(newValue)
    },
    [],
  );

  useEffect(() => {
    if (fieldBannerMetaProduct) {
      dispatch(getEntity(fieldBannerMetaProduct.value)).unwrap().then((res) => {
        if ((res.data)) {
          setProduct(res.data)
        }
      }).catch((e) => {
        console.log("E125 >>>>", e);
      });
    }
  }, [fieldBannerMetaProduct])

  const BannerMetaProduct = () => {
    return (
      <LegacyCard>
        <ResourceList
          resourceName={{ singular: 'product', plural: 'products' }}
          items={products ?? []}
          loading={loading}
          renderItem={(item) => {
            const { product_id, product_media, product_name } = item;
            const media = <Thumbnail source={product_media[0] || NoteIcon} alt="" />;

            return (
              <ResourceItem
                id={product_id as string}
                url={null}
                key={product_id as string}
                onClick={() => fieldBannerMetaProduct.onChange(product_id)}
                media={media}
                accessibilityLabel={`View details for ${product_name}`}
              >
                <Text variant="bodyMd" fontWeight="bold" as="h3">
                  {product_name}
                </Text>
              </ResourceItem>
            );
          }}
        />
      </LegacyCard>
    );
  };

  const BannerMetaCategory = () => {
    return (
      <Select
        label={'Danh mục sản phẩm'}
        options={optionCategories}
        value={fieldBannerMetaCategory.value}
        onChange={(e) => fieldBannerMetaCategory.onChange(e)}
        {...fieldBannerMetaCategory}
      />
    );
  };

  const BannerMetaLink = () => {
    return (
      <TextField
        autoComplete="off"
        autoFocus
        label={'Link gắn cho banner'}
        {...fieldBannerMetaLink}
      />
    );
  };

  useEffect(() => {
    switch (bannerMetaType) {
      case BANNER_META_TYPE.PRODUCT:
        setRenderedComponent(<BannerMetaProduct />);
        break;
      case BANNER_META_TYPE.CATEGORY:
        setRenderedComponent(<BannerMetaCategory />);
        break;
      case BANNER_META_TYPE.LINK:
        setRenderedComponent(<BannerMetaLink />);
        break;
      default:
        setRenderedComponent(null);
        break;
    }
  }, [bannerMetaType, fieldBannerMetaCategory, fieldBannerMetaLink, fieldBannerMetaProduct, products]);

  return <>
    {
      bannerMetaType === BANNER_META_TYPE.PRODUCT ?
        <Card>
          <>
            {
              product?.product_id ?
                <ResourceList
                  resourceName={{ singular: 'product', plural: 'products' }}
                  items={[product]}
                  loading={loading}
                  renderItem={(item) => {
                    const { product_id, product_media, product_name } = item;
                    const media = <Thumbnail source={product_media[0] || NoteIcon} alt="" />;

                    return (
                      <div>
                        <Button variant="primary" onClick={() => setProduct(null)} accessibilityLabel="Add theme" >
                          Chọn sản phẩm khác
                        </Button>
                        <ResourceItem
                          id={product_id as string}
                          url={null}
                          key={product_id as string}
                          media={media}
                          accessibilityLabel={`View details for ${product_name}`}
                        >
                          <Text variant="bodyMd" fontWeight="bold" as="h3">
                            {product_name}
                          </Text>
                        </ResourceItem>
                      </div>
                    );
                  }}
                />
                :
                <>
                  <TextField
                    label="Tìm kiếm tên sản phẩm"
                    onChange={handleChange}
                    autoComplete="off"
                    value={value}
                  />
                  <br />
                  {renderedComponent}
                </>
            }
          </>
        </Card> :
        renderedComponent
    }

  </>;
}

export default memo(BannerMetaInfomation)
