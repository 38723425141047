import { DataTable, Page, Toast, InlineStack, Button, Card, EmptyState, Tooltip, Thumbnail, InlineGrid } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import dateandtime from 'date-and-time';
import { getBanners, clearError, deleteBanner } from 'store/setting_banner.store.reducer';
import { useAppDispatch, useAppSelector } from "config/store";
import __ from "languages/index";
import {
  EditIcon,
  DeleteIcon,
  NoteIcon,
} from '@shopify/polaris-icons';
import emptyIMG from "../../../media/images/empty.png";
import SkeletonLoading from "components/skeletonLoading";
import BannerNewModal from './component/banner.new.modal'
import LayoutChildrenProduct from "layout/layout.infomation.product";
import ImgsViewer from 'react-images-viewer'

export default function BannerList() {

  const loading = useAppSelector((state) => state.setting_banner.loading);
  const updating = useAppSelector((state) => state.setting_banner.updating);
  const updateSuccess = useAppSelector((state) => state.setting_banner.updateSuccess);
  const entities = useAppSelector((state) => state.setting_banner.entities);
  const [showModal, setShowModal] = useState(false)
  const [idBanner, setIdBanner] = useState('')
  const [bannerType, setBannerType] = useState('big');
  const [dataTable, setDataTable] = useState([]);
  const [currImg, setCurrImg] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [imgViewerUrl, setImgViewerUrl] = useState('')

  const dispatch = useAppDispatch();

  useEffect(() => {
    switch (bannerType) {
      case 'big':
        setDataTable(entities?.filter(val => val.banner_type === 'big' ?? []))
        break;
      case 'medium':
        setDataTable(entities?.filter(val => val.banner_type === 'medium' ?? []))
        break;
      default:
        break;
    }
  }, [entities, bannerType])

  /**
   * Change page number
   */

  useEffect(() => {
    dispatch(getBanners({}));
  }, []);

  const clearErrorCallback = useCallback(() => {
    dispatch(clearError());
  }, []);

  /** Clear Error */
  useEffect(() => {
    clearErrorCallback();
  }, []);

  // onClose modal
  const onClose = () => {
    setShowModal(false);
    setIdBanner('')
  }

  // call api delete
  const handleDeleteBanner = (banner_id) => {
    if (window.confirm('Bạn có chắc chắn muốn xoá không?')) {
      dispatch(deleteBanner(banner_id))
    }
  }

  // open viewer image
  const handleOnViewer = (url) => {
    setImgViewerUrl(url)
    setIsOpen(true)
  }

  const emptyData = (
    <EmptyState heading="Dữ liệu trống" image={emptyIMG}>
      <p>Không tìm thấy banner</p>
    </EmptyState>
  );

  const toastSuccessMarkup = updateSuccess ? (
    <Toast content={'Cập nhật thành công!'} onDismiss={clearErrorCallback} />
  ) : null;

  const items = (commission, index) => {
    const {
      banner_id,
      banner_title,
      banner_description,
      banner_status,
      banner_image_url,
      createdAt,
    } = commission;

    return [
      index + 1,
      banner_image_url ? <img style={{ width: '200px', cursor: 'pointer' }
      } src={banner_image_url} onClick={() => handleOnViewer(banner_image_url)}/> : <Thumbnail size="small" source={banner_image_url ? banner_image_url : NoteIcon} alt="" />
      ,

      banner_title,
      banner_description,
      <InlineStack gap={"200"} align="start" blockAlign="center">
        <Tooltip content="Sửa banner">
          <Button variant="tertiary" icon={EditIcon} onClick={() => {
            setIdBanner(banner_id)
            setShowModal(true)
          }} />
        </Tooltip>
        <Tooltip content="Xoá banner">
          <Button variant="tertiary" icon={DeleteIcon} onClick={() => handleDeleteBanner(banner_id)} />
        </Tooltip>
      </InlineStack>,
    ]
  }

  const bannerList = (
    <>
      {
        dataTable && dataTable.length > 0 ? (
          <DataTable
            hideScrollIndicator
            columnContentTypes={[
              'text',
              'text',
              'text',
              'text',
              'text',
            ]}
            headings={[
              "STT",
              "Banner",
              "Mô tả banner",
              "Nơi áp dụng",
              "Hành động",
            ]}
            rows={dataTable?.map(items)}
            footerContent={`Showing ${dataTable?.length} of ${dataTable?.length} results`}
            hasZebraStripingOnData
            increasedTableDensity
          />
        ) : emptyData
      }
    </>
  )

  return (
    <>
      {toastSuccessMarkup}
      {(loading || updating) ? <SkeletonLoading /> :
        <Page
          fullWidth
          title={"Quản lý ảnh Banner"}
        >
          <InlineStack gap={'300'}>
            <Button variant={bannerType === 'big' ? "primary" : "secondary"} onClick={() => setBannerType('big')}>Quản lý banner lớn</Button>
            <Button variant={bannerType === 'medium' ? "primary" : "secondary"} onClick={() => setBannerType('medium')}>Quản lý banner nhỏ</Button>
          </InlineStack>
          <br />
          <InlineGrid gap="300" columns={{ xs: '1', sm: '1', md: '1', lg: '1' }}>
            <Card>
              <LayoutChildrenProduct disable={bannerType === 'medium' && entities?.filter(val => val.banner_type === 'medium').length > 1} title={`Danh sách banner ${bannerType === 'medium' ? 'nhỏ ( tối đa 2 banner nhỏ )' : 'lớn'}`} btnText={`Tạo mới banner ${bannerType === 'medium' ? 'nhỏ' : 'lớn'}`} actionBtn={() => setShowModal(true)}>
                {bannerList}
              </LayoutChildrenProduct>
            </Card>
          </InlineGrid>
        </Page>
      }
      <BannerNewModal bannerType={bannerType} show={showModal} onClose={() => onClose()} banner_id={idBanner} />
      <ImgsViewer
        imgs={[
          {
            src: imgViewerUrl
          }
        ]}
        class={'test'}
        currImg={currImg}
        showThumbnails={false}
        isOpen={isOpen}
        onClickPrev={e => setCurrImg(currImg - 1)}
        onClickNext={e => setCurrImg(currImg + 1)}
        onClickThumbnail={index => setCurrImg(index)}
        onClose={e => setIsOpen(false)}
        showImgCount={false}
      />
    </>
  )
}