
import analytics from './analytics.store.reducer';
import user from './user.store.reducer';
import users from './users.store.reducer';
import user_referrer from './user_referrer.store.reducer';
import user_social_profile from './user_social_profile.store.reducer';
import user_meta from './user.meta.store.reducer';
import customer from './customer.store.reducer';
import order from './order.store.reducer';
import order_fullfillment from './order_fullfillment.store.reducer';
import order_fee from './order_fee.store.reducer';
import product from './product.store.reducer';
import posts from './posts.store.reducer';
import settings from './settings.store.reducer';
import transactions from './transactions.store.reducer';
import transaction_to_project from './transaction_to_project.store.reducer';
import transaction_to_order from './transaction_to_order.store.reducer';
import notification from './notification.store.reducer';
import to_do_list from './to_do_list.reducer';
import logistic_service from './logistic_service.store.reducer';
import stock from './stock.store.reducer';
import phonelog from './phonelog.store.reducer';
import smslog from './smslog.store.reducer';
import global_notification from './global.warning.store.reducer';
import achievement from './achievement.store.reducer';
import global_process_bar from './global_process_bar.store.reducer';
import product_category from './product_category.store.reducer';
import product_collection from './product_collection.store.reducer';
import product_variant from './product.variant.store.reducer';
import user_address_book from './customer_address_book.reducer'
import order_stock from './order_stock.store.reducer'
import group_user from './group_user.store.reducer'
import member from './member.store.reducer'
import commission from './commission.store.reducer'
import user_referrer_commission from './user_referrer_commission.store.reducer'
import user_group_commission from './user_group_commission.store.reducer'
import setting_banner from './setting_banner.store.reducer'


const rootReducer = {
  analytics,
  stock,
  logistic_service,
  product,
  order,
  order_fullfillment,
  order_fee,
  user,
  user_meta,
  user_referrer,
  user_social_profile,
  users,
  customer,
  settings,
  posts,
  notification,
  transactions,
  transaction_to_project,
  transaction_to_order,
  to_do_list,
  phonelog,
  smslog,
  global_notification,
  achievement,
  global_process_bar,
  product_category,
  product_collection,
  product_variant,
  user_address_book,
  order_stock,
  group_user,
  member,
  commission,
  user_referrer_commission,
  user_group_commission,
  setting_banner
};

export default rootReducer;
