import axios from 'axios';
import { createAsyncThunk, isFulfilled } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { TypedCollection } from '../interface/product.model';

/**
*   Reducer used for front-end, with media.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using media.model.ts
*/


// interface getQuery extends IQueryParams, TypedProduct{};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: null as any as TypedCollection[],
  entity: null as TypedCollection,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};
const apiUrl = 'v2/product_collection';

// Actions

export const getCollections = createAsyncThunk('product_collection/fetch_entity_list', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return axios.get<TypedCollection[]>(requestUrl);
});

export const getEntity = createAsyncThunk('product_collection/fetch_entity', async (product_category_id: string) => {
  const requestUrl = `${apiUrl}/${product_category_id}`;
  return axios.get<TypedCollection>(requestUrl);
},
  { serializeError: serializeAxiosError }
);

// edit category product
export const editCollectionProduct = createAsyncThunk(
  'product_collection/edit_product_collection',
  async (entity: any, thunkAPI) => {
    const { product_collection_id, ...dataForm } = entity
    const result = await axios.patch<TypedCollection>(`${apiUrl}/${product_collection_id}`, helpers.cleanEntity(dataForm));
    thunkAPI.dispatch(getCollections({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// delete category product
export const deleteCollectionProduct = createAsyncThunk(
  'product_collection/delete_product_collection',
  async (product_collection_id: any, thunkAPI) => {
    const result = await axios.delete<TypedCollection>(`${apiUrl}/${product_collection_id}`);
    thunkAPI.dispatch(getCollections({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// create category product
export const createCollectionProduct = createAsyncThunk(
  'product_collection/create_product_category',
  async (entity: any, thunkAPI) => {
    const result = await axios.post<TypedCollection>(`${apiUrl}`, helpers.cleanEntity(entity));
    thunkAPI.dispatch(getCollections({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const Reducer_Product_Collection = createEntitySlice({
  name: 'product_collection',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
      state.updateSuccess = false;
    }
  },
  extraReducers(builder) {
    builder

      .addCase(createCollectionProduct.pending, (state, action) => {
        state.loading = true;
        state.updateSuccess = false;
      })
      .addCase(createCollectionProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess = true;
      })
      .addCase(createCollectionProduct.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "Thêm nhóm sản phẩm không thành công";
        state.updateSuccess = false;
      })

      .addCase(editCollectionProduct.pending, (state, action) => {
        state.loading = true;
        state.updating = true;
        state.updateSuccess = false;
      })
      .addCase(editCollectionProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = true;
      })
      .addCase(editCollectionProduct.rejected, (state, action) => {
        state.loading = false;
        state.updating = false;
        state.errorMessage = "Sửa nhóm sản phẩm không thành công";
        state.updateSuccess = false;
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.entity = action.payload.data;
        state.loading = false;
      })
      .addMatcher(isFulfilled(getCollections), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: (action.payload as any).data,
          entity: null,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
  },
});

export const { clearError, reset } = Reducer_Product_Collection.actions;

// Reducer
export default Reducer_Product_Collection.reducer;

export const collectionsSelector = (store) => store.product_collection.entities;