export enum OrderStatus {
    DRAFT = "draft",
    APPROVED = "approved",
    TRANSPORTING = "transporting",
    COMPLETED = "completed",
    CANCEL = "cancel",
    RETURNED = "returned",
    REFUNDED = "refunded",
    CUSTOMER_CANCEL = "customer_cancel",
    LOCK = "order_lock"
}

export enum PaymentStatus {
    UNPAID = 0, //chưa thanh toán
    PARTIAL = 1, //thanh toán 1 phần,
    PAID = 2 // đã thanh toán
}

export enum LockedOrder {
    UNLOCKED = 0,
    LOCKED = 1
};


export enum ORDER_FULLFILLMENT_STATUS_ENUM {
    EMPTY = 0,
    PROCESSING = 10,
    PACKED = 11,
    SENT = 12,
    TRANSPORTING = 13,
    DONE = 14,
    RETURNED = 15,
    ERROR = 16,
    CANCEL = 19,
}