import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import __helpers from "helpers/index";
import { TypedUserReferrer } from 'interface/user_referrer.model';


export const initialState = {
  loading: false,
  entity: {} as TypedUserReferrer,
  entities: null as TypedUserReferrer[],
  errorMessage: null as unknown as string, // Errors returned from server side
  updateSuccess: false,
  totalItems: 0,

};

const apiUrl = 'v2/user_referrer/admin';

export const getEntities = createAsyncThunk('user_referrer/fetch_entity_list', async (object: any) => {
  const EndURL = __helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return axios.get<TypedUserReferrer[]>(requestUrl);
},
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'user_referrer/delete_entity',
  async (entity:{ user_referrer_id: string}, thunkAPI) => {
    const result = await axios.delete<TypedUserReferrer>(`${apiUrl}/${entity.user_referrer_id}`);
    thunkAPI.dispatch(getEntities({}))
    return result;
  }
);

export const createEntities = createAsyncThunk(
  'user_referrer/create_entity',
  async (entity: any, thunkAPI) => {
    const result = await axios.post<TypedUserReferrer>(`${apiUrl}`, __helpers.cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}))
    return result;
  }
);


export const USER_REFERRER = createEntitySlice({
  name: 'user_referrer',
  initialState: initialState as any,
  reducers: {
    clearError: (state) => {
      state.errorMessage  = null;
      state.updateSuccess = false;
    },
    reset: (state) => {
      return {...state, ...initialState};
    }
  },

  extraReducers(builder) {
    builder
    .addMatcher(isFulfilled(getEntities), (state, action) => {
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    })
    .addMatcher(isPending(getEntities), state => {
      state.errorMessage = null;
      state.updateSuccess = false;
      state.totalItems = 0;
      state.loading = true;
      state.entity = null;
    })
     
      
  },
});
export const {clearError, reset} = USER_REFERRER.actions;
// Reducer
export default USER_REFERRER.reducer;