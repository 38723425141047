import { DataTable, Page, Toast, InlineStack, Button, Layout, Card, EmptyState, Tooltip, Badge } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import dateandtime from 'date-and-time';
import { getCollections, clearError, deleteCollectionProduct } from 'store/product_collection.store.reducer';
import { useAppDispatch, useAppSelector } from "config/store";
import Pagination from "components/pagination";
import helpers from "helpers/index";
import __ from "languages/index";
import {
  PlusIcon,
  EditIcon,
  DeleteIcon,
  DatabaseAddIcon
} from '@shopify/polaris-icons';
import emptyIMG from "../../media/images/empty.png";
import { useNavigate } from "react-router-dom";
import SkeletonLoading from "components/skeletonLoading";
import ProductCategoryNewModal from './component/product_collection.new.modal'
import { wrapText } from "utils";

export default function ProductCollectionList() {

  const loading = useAppSelector((state) => state.product_collection.loading);
  const updating = useAppSelector((state) => state.product_collection.updating);
  const updateSuccess = useAppSelector((state) => state.product_collection.updateSuccess);
  const entities = useAppSelector((state) => state.product_collection.entities);
  const [showModal, setShowModal] = useState(false)
  const totalItems = useAppSelector((state) => state.product_collection.totalItems);
  const [idProductCollection, setIdProductCollection] = useState('')
  const account = useAppSelector(state => state.user.account);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  const rootSearchPath = window.location.search;
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;

  const initialQuery = {
    query: "",
    page: 1,
    limit: 40,
    sort: "createdAt:desc",
  };

  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery,
  });



  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({ ...mainQuery, page: numPage, limit: limit });
  }, [mainQuery]);

  useEffect(() => {
    dispatch(getCollections(mainQuery));
  }, [mainQuery]);

  const clearErrorCallback = useCallback(() => {
    dispatch(clearError());
  }, []);

  // const deleteStock = useCallback((stock_id) => {
  //     dispatch( deleteEntity(stock_id) );
  // }, []);


  /** Clear Error */
  useEffect(() => {
    clearErrorCallback();
  }, []);

  // onClose modal
  const onClose = () => {
    setShowModal(false);
    setIdProductCollection('')
  }

  // call api delete user referrer commission
  const handleDeleteProductCollection = (category_id) => {
    if (window.confirm('Bạn có chắc chắn muốn xoá ?')) {
      dispatch(deleteCollectionProduct(category_id))
    }
  }

  const emptyData = (
    <EmptyState heading="Dữ liệu trống" image={emptyIMG}>
      <p>Không tìm thấy danh mục sản phẩm</p>
    </EmptyState>
  );

  const toastSuccessMarkup = updateSuccess ? (
    <Toast content={'Cập nhật thành công!'} onDismiss={clearErrorCallback} />
  ) : null;

  const items = (commission, index) => {
    const {
      collection_id,
      collection_name,
      collection_description,
      collection_count,
      createdAt,
    } = commission;

    return [
      index + 1,
      <InlineStack gap={"200"} align="start">
        <span>{collection_name}</span>
      </InlineStack>,
      collection_description ?  <div dangerouslySetInnerHTML={{ __html: wrapText(collection_description, 50) }}></div> : <Badge></Badge>,
      collection_count,
      createdAt ? <span>{dateandtime.format(new Date(+createdAt), 'DD-MM-YYYY')}</span> : '',
      <InlineStack gap={"200"} align="start" blockAlign="center">
        <Tooltip content={`Danh sách sản phẩm của bộ sưu tập ${collection_name}`}>
          <Button variant="tertiary" onClick={() => history(`/product_collection/detail/${collection_id}`, { state: { collection_name: collection_name } })} icon={DatabaseAddIcon} />
        </Tooltip>
        {(['admin'].includes(account?.user_role)) &&
          <Tooltip content="Sửa nhóm sản phẩm">
            <Button variant="tertiary" icon={EditIcon} onClick={() => {
              setIdProductCollection(collection_id)
              setShowModal(true)
            }} />
          </Tooltip>
        }
        {(['admin'].includes(account?.user_role)) &&
          <Tooltip content="Xoá nhóm sản phẩm">
            <Button variant="tertiary" icon={DeleteIcon} onClick={() => handleDeleteProductCollection(collection_id)} />
          </Tooltip>
        }
      </InlineStack>
    ]
  }

  const productCollectionList = (
    <>
      {
        entities && entities.length > 0 ? (
          <DataTable
            hideScrollIndicator
            columnContentTypes={[
              'text',
              'text',
              'text',
              'text',
              'text',
              'text',
              'text',
            ]}
            headings={[
              "STT",
              "Tên bộ sưu tập sản phẩm",
              "Mô tả bộ sưu tập sản phẩm",
              "Số lượng sản phẩm thuộc bộ sưu tập",
              "Ngày tạo",
              (['admin'].includes(account?.user_role)) && "Hành động",
            ]}
            rows={entities?.map(items)}
            footerContent={`Showing ${entities?.length} of ${entities?.length} results`}
            hasZebraStripingOnData
            increasedTableDensity
          />
        ) : emptyData
      }
    </>
  )

  return (
    <>
      {toastSuccessMarkup}
      {(loading || updating) ? <SkeletonLoading /> :
        <Page
          fullWidth
          title={"Nhóm sản phẩm"}
          subtitle=""
          primaryAction={
            (['admin'].includes(account?.user_role)) &&
            <Button
              icon={PlusIcon}
              variant="primary"
              onClick={() => {
                setShowModal(!showModal)
              }}>
              Thêm nhóm sản phẩm
            </Button>}
        >
          <Layout>
            <Layout.Section>
              <Card padding={'0'}>
                {productCollectionList}
              </Card>
              <br />
              {totalItems > mainQuery.limit ? (
                <Pagination
                  TotalRecord={totalItems}
                  activeCurrentPage={mainQuery.page}
                  pageSize={mainQuery.limit}
                  onChange={onChangePagination}
                />
              ) : null}
            </Layout.Section>
          </Layout>
        </Page>
      }
      <ProductCategoryNewModal show={showModal} onClose={() => onClose()} product_collection_id={idProductCollection} />
    </>
  )
}