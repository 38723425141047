import {
  FormLayout,
  Page,
  TextField,
  Form,
  Button,
  Toast,
  InlineStack,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "config/store";
import { clearError, createEntity } from "store/users.store.reducer";
import { useLocation, useParams } from "react-router-dom";

import helpers from "helpers";

import {
  lengthLessThan,
  notEmpty,
  useField,
  useForm,
} from "@shopify/react-form";

import {
  updateEntity,
  getEntity
} from "../../store/users.store.reducer";
import AvatarUpload from './components/avatar.upload'
import __, { ___ } from "languages/index";
import { slugify } from "utils";
import {
  ViewIcon,
  HideIcon,
} from '@shopify/polaris-icons';

export default function CustomerNewOrEditPage() {
  const entity = useAppSelector((state) => state.users.entity);
  const updating = useAppSelector((state) => state.users.updating);
  const updateSuccess = useAppSelector((state) => state.users.updateSuccess);
  const [showPassWord, setShowPassword] = useState(false)
  const [internalErrorNotice, setInternalErrorNotice] = useState("");
  const [viewPasswordMode, setViewPasswordMode] = useState(false);
  const account = useAppSelector(state => state.user.account);

  const dispatch = useAppDispatch();
  const location = useLocation();
  /**
   * Lấy URL ra... để query. chú ý phải load nội dung mới 
   * trong useEffect
   */
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.user_id || false;

  const convertLabelRoles = (value) => {
    switch (value) {
      // case 'customer':
      //   return 'Khách hàng'
      //   break;
      // case 'user':
      //   return 'Người dùng'
      //   break;
      case 'sale':
        return 'Nhân viên sale'
        break;
      // case 'sale_admin':
      //   return 'Quản lý nhân viên sale'
      //   break;
      case 'stock_manager':
        return 'Quản lý kho hàng'
        break;
      // case 'accountant':
      //   return 'Nhân viên kế toán'
      //   break;
      case 'admin':
        return 'Quản trị viên toàn quyền'
        break;

      default:
        break;
    }
  }
  
  useEffect(() => {
    if (Param) {
      dispatch(getEntity(Param));
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  }, [Param])

  const useFields = {
    display_name: useField<string>({
      value: entity && Param ? entity?.display_name : '',
      validates: [
        lengthLessThan(50, __('user_full_name_less_than')),
        (inputValue) => {
          if (inputValue.length < 6) {
            return __('user_full_name_too_short');
          }
        },
      ],
    }),

    password: useField<string>({
      value: '',
      validates: [
        lengthLessThan(50, "Mật khẩu nhỏ hơn 50 ký tự"),
        (inputValue) => {
          if (inputValue && inputValue.length < 6) {
            return __('Mật khẩu lớn hơn 6 ký tự');
          }
          if(!inputValue && !Param){
            return __('Mật khẩu lớn hơn 6 ký tự');
          }
        },
      ],
    }),

    user_email: useField<string>({
      value: entity && Param ? entity?.user_email : '',
      validates: [
        notEmpty(__('email_required')),
        (inputValue) => {
          if (!helpers.isEmail(inputValue)) {
            return __('email_invalid');
          }
        },
      ],
    }),

    user_phonenumber: useField<string>({
      value: entity && Param ? helpers.formatPhoneNumber(entity?.user_phonenumber) : '',
      validates: [
        (inputValue) => {
          if (inputValue && !helpers.isPhoneNumber(inputValue)) {
            return __('user_phone_invalid');
          }
          if (!inputValue) {
            return __('require');
          }
        }
      ],
    }),

    user_avatar: useField<string[]>({
      value: entity && Param ? [entity?.user_avatar] : [],
      validates: [
      ],
    }),

    // user_birthday: useField<string>({
    //   value: entity?.user_birthday || "",
    //   validates: [
    //     (inputValue) => {
    //       const formatDate = dateandtime.format(new Date(inputValue), "DD/MM/YYYY")

    //       if (
    //         !new RegExp(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/, "g").test(formatDate)
    //       ) {
    //         return __('user_birthday_invalid');
    //       }
    //     },
    //   ],
    // }),

  }

  const {
    fields,
    submit,
    dirty,
    reset: resetForm,
  } = useForm({
    fields: useFields,

    async onSubmit(values) {
      try {
        if (entity && Param) {
          // update
          await dispatch(
            updateEntity({
              user_id: Param,
              user_email: values.user_email,
              display_name: values.display_name,
              user_phonenumber: values.user_phonenumber,
              user_avatar: values.user_avatar[0] ?? "",
              user_role: 'customer',
              user_password: values?.password
            })
          );
        } else {
          // create new
          await dispatch(
            createEntity({
              user_email: values.user_email,
              display_name: values.display_name,
              user_phonenumber: values.user_phonenumber,
              user_login: slugify(values.display_name),
              user_avatar: values.user_avatar[0] ?? "",
              user_to_permission: [],
              user_role: 'customer',
              user_password: values?.password
            })
          )
        }
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message =
          e?.response?.data?.title ?? "Undefined error. Try again!";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });
  /* End form */

  const clearErrorCallback = useCallback(() => {
    dispatch(clearError());
  }, []);

  const successNotice = updateSuccess ? (
    <Toast content={'Cập nhật thành công!'} onDismiss={clearErrorCallback} />
  ) : null;

  const handUploadError = useCallback((err: any) => {
    setInternalErrorNotice(err);
  }, []);

  const Actual_page = (
    <Page
      title={entity && Param ? "Thông tin người dùng" : "Tạo mới người dùng"}
      primaryAction={(['admin'].includes(account?.user_role)) && {
        content: entity && Param ? "Lưu thông tin" : "Tạo mới",
        disabled: !dirty,
        onAction: submit
      }}
      backAction={{
        content: 'Customer',
        url: location?.state?.locationCurrent ? location?.state?.locationCurrent : '/customer'
      }}
      fullWidth
    >
      <Form onSubmit={submit}>
        <FormLayout>
          <AvatarUpload
            fieldUrl={fields.user_avatar}
            onError={handUploadError}
          />
          <TextField
            autoFocus
            label={__('user_full_name')}
            requiredIndicator
            autoComplete="off"
            {...fields.display_name}
          />

          {
            showPassWord ? (
              <TextField
                autoFocus
                type={viewPasswordMode ? "text" : "password"}
                label={"Mật khẩu"}
                autoComplete="off"
                {...fields.password}
                suffix={(
                  <InlineStack blockAlign="center">
                    <Button variant="plain" onClick={() => setViewPasswordMode(!viewPasswordMode)} icon={viewPasswordMode ? ViewIcon : HideIcon}></Button>
                  </InlineStack>
                )}
              />
            ) :
              (
                <Button variant='plain' onClick={() => setShowPassword(!showPassWord)} >Cập nhập mật khẩu mới</Button>
              )

          }

          <TextField
            autoComplete="off"
            requiredIndicator
            label={'Email'}
            helpText="Ví dụ: example@domain.com"

            {...fields.user_email}
          />

          <TextField
            label={__('phone_number')}
            autoComplete="off"
            helpText="Ví dụ: 0906111111"
            {...fields.user_phonenumber}
          />
          {/* <TextField
              label={__('birth_of_day')}
              type="date"
              helpText="Nhập định dạng ngày/tháng/năm"
              autoComplete="off"
              {...fields.user_birthday}
            /> */}

        </FormLayout>
      </Form>
    </Page>
  );

  return (
    <>
      {successNotice}
      {internalErrorNotice ? (
        <Toast
          content={internalErrorNotice}
          error
          onDismiss={() => setInternalErrorNotice("")}
        />
      ) : null}
      {Actual_page}
    </>
  );
}
