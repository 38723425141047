import { Form, Modal, Text, ResourceItem, ResourceList, TextField, Card } from "@shopify/polaris";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "config/store";
import {
    getEntities,
} from "../../store/customer_address_book.reducer";
import helpers from "helpers/index";

export default function OrderCustomerSearchForm({ show, onClose }) {

    const dispatch = useAppDispatch();
    let entities = useAppSelector((state) => state.user_address_book.entities);
    const loading = useAppSelector((state) => state.customer.loading);

    useEffect(() => {
        onChangeText('');
    }, []);

    const closeBtn = useCallback(() => {
        onClose();
    }, []);

    const [customerNumberphone, setCustomerNumberphone] = useState('');
    const onChangeCallback = useMemo(() => helpers.debounce(_value => onChangeText?.call(this, _value), 300), []);
    function onChangeText(_t: string) {
        dispatch(getEntities({
            limit: 2,
            query: _t
        }))
    }
    const handleCustomerPhonenumberChange = useCallback(
        (newValue: string) => {
            setCustomerNumberphone(newValue);
            onChangeCallback(newValue)
        },
        [],
    );
    /**
     * selectableCallback
     * @param customer_id 
     */
    function selectableCallback(customer_id: any) {
        onClose(customer_id); // truyen ID sang ben khac
    }

    return (
        <>
            <Modal
                activator={null}
                open={show}
                onClose={closeBtn}
                title="Tìm khách hàng"
            >
                <Modal.Section>
                    <Form onSubmit={null}>
                        <TextField
                            label="Tên, số điện thoại hoặc địa chỉ"
                            value={customerNumberphone}
                            onChange={handleCustomerPhonenumberChange}
                            autoComplete="off"
                        />
                    </Form>
                    <br />
                    <Card padding="0">
                        <ResourceList
                            resourceName={{ singular: 'customer', plural: 'customers' }}
                            items={entities ?? []}
                            loading={loading}
                            renderItem={(item) => {
                                const {
                                    user_id,
                                    user_display_name,
                                    user_address,
                                    user_phonenumber,
                                    user_address_city_name,
                                    user_address_district_name,
                                    user_address_ward_name
                                } = item;

                                return (
                                    <ResourceItem
                                        id={user_id}
                                        url={null}
                                        onClick={() => {
                                            selectableCallback(user_id);
                                        }}
                                    >
                                        <Text variant="bodyMd" fontWeight="bold" as="h3">
                                            {user_display_name}
                                        </Text>
                                        <div>
                                            {user_phonenumber ? + user_phonenumber : ''}
                                            {user_address ? ' / ' + user_address : ''}
                                            {user_address_ward_name ? ' / ' + user_address_ward_name : ''}
                                            {user_address_district_name ? ' / ' + user_address_district_name : ''}
                                            {user_address_city_name ? ' / ' + user_address_city_name : ''}


                                            { }
                                        </div>
                                    </ResourceItem>
                                );
                            }}
                        />
                    </Card>

                </Modal.Section>
            </Modal>
        </>
    );
}