const USER_CAPACITY_LIST = [
    // 'customer',
    // 'user',
    'sale',
    // 'sale_admin',
    'stock_manager',
    'accountant',
    'admin',
    // 'super_admin',
]

export {
    USER_CAPACITY_LIST
}

export const ENDPOINT_URL = "v2"

export const SHOW_PRODUCT = {
    SHOW: '1',
    HIDDEN: '0'
}

export const COMMISSION_STATUS = [
    {
      value: "0",
      label: "Không cộng dồn hoa hồng"
    },
    {
      value: "1",
      label: "Cộng dồn hoa hồng"
    },
  ]

export const COMMISSION_UNIT = [
    {
        value: "money",
        label: "Tiền (vnđ)"
    },
    {
        value: "percent",
        label: "Phần trăm (%)"
    },
]

export const COMMISSION_RULE_FIELD = [
    {
        value: "user_point",
        label: "Điểm khách hàng"
    },
    {
        value: "user_group",
        label: "Nhóm khách hàng"
    },
    {
        value: "user_role",
        label: "Quyền khách hàng"
    },
    {
        value: "product_category",
        label: "Loại sarn phẩm"
    },
    {
        value: "order_quantity",
        label: "Số lượng đơn hàng"
    },
]

export const COMMISSION_RULE_OPERATER = [
    {
        value: "equal",
        label: "Bằng nhau"
    },
    {
        value: "not_equal",
        label: "Không bằng nhau"
    },
    {
        value: "less_than",
        label: "Nhỏ hơn"
    },
    {
        value: "greater_than",
        label: "Lớn hơn"
    },
]

export const USER_STATUS = {
    ACTIVE: 1,
    IN_ACTIVE: 0
}

export const MEMBER_GROUP_STATUS = {
    ACTIVE: 1,
    IN_ACTIVE: 0
}

export const GROUP_APPROVED_STATUS = {
    APPROVED_NOT_REQQUIRE: 0,
    APPROVED_REQUIRE: 1
}

export const BANNER_META_TYPE = {
    PRODUCT: 'product',
    CATEGORY: 'category',
    LINK: 'link',
    COLLECTION: 'collection'
}

export const USER_REFERRER_COMMISSION_RULE_FIELD = [
    {
        value: "1",
        label: "Chi nhánh nhượng quyền - B1"
    },
    {
        value: "2",
        label: "Tổng đại lý"
    },
    {
        value: "3",
        label: "Đại lý"
    },
    {
        value: "4",
        label: "Nhà phân phối"
    }
]

export const REGEX_VALIDATE_NUMBER = /^[1-9]\d*(\.\d+)?$/

export const dataTimeYear = ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6' , 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12' ]

