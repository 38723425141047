import {
  DataTable,
  EmptyState,
  Layout,
  Page,
  Toast,
  Link,
  InlineStack,
  Avatar,
  Card,
  Button,
  Badge,
  Popover,
  Icon,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";
import emptyIMG from "../../media/images/empty.png";
import Pagination from "../../components/pagination";
import { clearError, getEntities, updateEntity } from "../../store/users.store.reducer";
import helpers from "../../helpers";
import __ from "languages/index";
import { TypedUserInternal } from "interface/customer.model";
import { EditIcon, ChevronDownIcon } from '@shopify/polaris-icons'
import { USER_STATUS } from "constant/index";
import {
  PlusIcon
} from '@shopify/polaris-icons';
import SimpleFilter from "components/simpleFilter";

export default function CustomerList() {
  const entities = useAppSelector((state) => state.users.entities);
  // const [entities, setEntities] = useState<TypedUserInternal[]>(entitiesFake);
  const loading = useAppSelector((state) => state.users.loading);
  const updateSuccess = useAppSelector((state) => state.users.updateSuccess);
  const totalItems = useAppSelector((state) => state.users.totalItems);
  const account = useAppSelector(state => state.user.account);

  const dispatch = useAppDispatch();
  const history = useNavigate();

  const toggleActive = useCallback(() => {
    dispatch(clearError());
  }, []);


  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery: any = helpers.ExtractUrl(useParam.search) || false;

  const initialQuery = {
    query: "",
    page: 1,
    limit: 20,
    sort: "createdAt: desc",
    user_role: 'customer,user',
  }
  const [mainQuery, setMainQuery] = useState({
    ...StringQuery,
    ...initialQuery
  });

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({ ...mainQuery, page: numPage, limit: limit });
  }, [mainQuery]);


useEffect(() => {
    dispatch(getEntities(mainQuery));
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  let m = mainQuery;

  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      m = initialQuery;
      return setMainQuery(initialQuery);
    } else {
      m = Object.assign({}, m, _value);
      setMainQuery(m);
    }
  }, []);

  /**
   *
   * @param user_id
   */
  const shortcutActions = (user_id: number | string) => {
    history("/customer/view/" + user_id);
  };

  const emptyData = (
    <EmptyState heading="Dữ liệu trống" image={emptyIMG}>
      <p>Không tìm thấy người dùng</p>
    </EmptyState>
  );

  const [showPopupForChangeUserStatus, setShowPopupForChangeUserStatus] = useState('');

  const changeUserStatus = useCallback((user_id: string, new_status: number) => {
    setShowPopupForChangeUserStatus('');
    dispatch(updateEntity({
      user_id: user_id,
      user_status: new_status
    })).then(() => {
      dispatch(getEntities(mainQuery))
    });
  }, []);

  const convertUserStatus = (value) => {
    switch (value) {
      case USER_STATUS.IN_ACTIVE:
        return <Badge tone="critical-strong">Tài khoản bị khoá</Badge>
        break;
      case USER_STATUS.ACTIVE:
        return <Badge tone="success">Tài khoản đang hoạt động</Badge>
        break;
      default:
        break;
    }
  }

  const handleSort = useCallback(
    (index, direction) => {
      let _direction = direction === "descending" ? "desc" : "asc";
      let sort = "";
      switch (index) {
        case 0:
          sort = "display_name:";
          break;
        case 1:
          sort = "user_role:";
          break;
        case 2:
          sort = "user_email:";
          break;
        case 3:
          sort = "user_phone:";
          break;
        case 4:
          sort = "user_birthday:";
          break;
        case 5:
          sort = "description:";
          break;

        default:
          break;
      }

      if (sort.length) {
        entities.sort((a, b) => {
          if (_direction == 'desc') {
            return a[sort] - b[sort]
          }
          return b[sort] - a[sort]
        })
      }

    },
    [entities]
  );

  const renderItem = (users: TypedUserInternal, index) => {
    const {
      user_id,
      user_email,
      user_avatar,
      display_name,
      user_phonenumber,
      // user_birthday,
      user_status
    } = users;

    const user_display_name = display_name || user_email || user_phonenumber || 'noname';
    // const birthDay = dateandtime.format(new Date(user_birthday), 'DD/MM/YYYY')
    return [
      index + 1,
      <InlineStack gap={"200"} align="start" blockAlign="center">
        <Avatar source={user_avatar} name={user_display_name} initials={user_display_name.charAt(0)} size="sm" />
        <Link
          key={user_id}
          onClick={() => shortcutActions(user_id)}
        >
          {display_name}
        </Link>
      </InlineStack>,
      <Popover
          active={showPopupForChangeUserStatus === user_id}
          activator={<>
            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => setShowPopupForChangeUserStatus(user_id)}>
                {
                  convertUserStatus(user_status)
                }
                <Icon
                  source={ChevronDownIcon}
                  tone="base"
                />
            </div>
          </>}
          autofocusTarget="first-node"
          onClose={() => setShowPopupForChangeUserStatus('')}
        >
          <div style={{ padding: 10, display: 'flex', flexDirection: 'column' }}>
            { user_status === USER_STATUS.ACTIVE ?
              <div style={{cursor: 'pointer' }} onClick={() => changeUserStatus(user_id, USER_STATUS.IN_ACTIVE)}><Badge tone="critical-strong">Khoá tài khoản</Badge></div>
              : <div style={{cursor: 'pointer' }} onClick={() => changeUserStatus(user_id, USER_STATUS.ACTIVE)}><Badge tone="success" >Mở tài khoản</Badge></div>
          }
          </div>
        </Popover>,
      // convertLabelRoles(user_role),
      user_email,
      helpers.formatPhoneNumber(user_phonenumber) ?? <Badge></Badge>,
      // birthDay,
      <InlineStack gap={"200"} blockAlign="center">
        <Button variant="tertiary" onClick={() => history(`/customer/edit/${user_id}`)} icon={EditIcon} />
      </InlineStack>
    ];
  };

  const UsersList = (
    <>
      {
        entities.length > 0 ? (
          <DataTable
            sortable={[
              false,
              false,
              false,
              false,
              false,
              false,
            ]}
            defaultSortDirection="descending"
            initialSortColumnIndex={0}
            onSort={handleSort}
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
            ]}
            headings={[
              "STT",
              __('user_full_name'),
              "Trạng thái",
              "Email",
              __('phone_number'),
              (['admin'].includes(account?.user_role)) && __('action'),
            ]}
            rows={entities?.map(renderItem)}
            hideScrollIndicator
            footerContent={`Hiển thị trang ${mainQuery.page} trong tổng số ${totalItems} kết quả...`}
          />
        ) : (emptyData)
      }
    </>
  );

  const Actual_page = (
    <>
      <Page
        fullWidth
        title={'Quản lý người dùng vàp APP'}
        subtitle="( Tất cả các người dùng vào APP đã kích hoạt email, điện thoại nhưng chưa đăng ký chở thành đại lý. )"
        primaryAction={(['admin'].includes(account?.user_role)) && <Button icon={PlusIcon} variant="primary" onClick={() => history('/customer/new')}>Thêm tài khoản mới</Button>}
      >
        <Layout>
          <Layout.Section>
            <Card padding={'0'}>
            <SimpleFilter loading={loading}
                onCallback={filterCallback}
                options={[
                  { label: 'Tài khoản đang hoạt động', value: '1', field: 'user_status' },
                  { label: 'Tài khoản bị khoá', value: '0', field: 'user_status' },
                ]}
                sortField={[
                  { label: 'Tài khoản bị khoá', field: 'user_status' },
                  { label: 'Tài khoản đang hoạt động', field: 'user_status' },
                ]}
                totalItems={totalItems}
              />
              {UsersList}
            </Card>
            <br />
            {totalItems > mainQuery.limit ? (
              <Pagination
                TotalRecord={totalItems}
                activeCurrentPage={mainQuery.page}
                pageSize={mainQuery.limit}
                onChange={onChangePagination}
              />
            ) : null}
          </Layout.Section>
        </Layout>
      </Page>

    </>
  );


  const toastMarkupNotification = updateSuccess ? (
    <Toast content={"Cập nhật thông tin thành công!"} onDismiss={toggleActive} />
  ) : null;

  return (
    <>
      {toastMarkupNotification}
      {Actual_page}
    </>
  );
}
