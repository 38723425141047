import { BlockStack, InlineGrid, Layout, LegacyCard, Select, SelectOption, TextField } from "@shopify/polaris";
import { Field } from "@shopify/react-form";
import { useAppDispatch } from "config/store";
import { SHOW_PRODUCT } from "constant";
import __ from "languages/index";
import LayoutChildrenProduct from "layout/layout.infomation.product";
import { memo, useEffect, useState } from "react";
import { getCategories } from "store/product_category.store.reducer";
import { getCollections } from "store/product_collection.store.reducer";
import helpers from "../../../helpers";

interface TypedProps {
    fieldProductName: Field<string>;
    fieldCollection: Field<string>;
    fieldProductCategory: Field<string>;
    fieldProductUnit: Field<string>;
    fieldProductCode: Field<string>;
    fieldOriginalPrice: Field<string>;
    fieldProductStatus: Field<string>;
    fieldProductWidth: Field<string>;
    fieldProductWeight: Field<string>;
    fieldProductHeight: Field<string>;
    fieldProductLength: Field<string>;
    fieldProductPriceB1: Field<string>;
    fieldProductPriceB2: Field<string>;
    fieldProductPriceB3: Field<string>;
    fieldVat: Field<string>;
    setStockProduct
    stockProducts
};

const InfomationGeneral = (props: TypedProps) => {
    const dispatch = useAppDispatch();
    const { fieldProductName,
        fieldCollection,
        fieldProductCategory,
        fieldProductUnit,
        fieldProductCode,
        fieldOriginalPrice,
        fieldProductStatus,
        fieldProductWidth,
        fieldProductWeight,
        fieldProductHeight,
        fieldProductLength,
        fieldProductPriceB1,
        fieldProductPriceB2,
        fieldProductPriceB3,
        setStockProduct,
        stockProducts,
        fieldVat
    } = props;

    const [optionCategories, setOptionCategories] = useState<SelectOption[]>([])
    const [optionTypeProduct, setOptionTypeProduct] = useState<SelectOption[]>([])
    const [optionStocks, setOptionStocks] = useState<SelectOption[]>([])
    const [valueStockQuantity, setValueStockQuantity] = useState('');

    //call api list type, category, distributor of product here
    useEffect(() => {

        dispatch(getCategories({})).unwrap().then((res) => {
            if (Array.isArray(res.data)) {
                const data = res.data.map(i => ({
                    value: i.category_id,
                    label: i.category_name
                }))
                setOptionCategories([{ value: '', label: '' }, ...data]);
            }
        }).catch((e) => {
            console.log("E125 >>>>", e);
        });


        dispatch(getCollections({})).unwrap().then((res) => {

            if (Array.isArray(res.data)) {
                const data = res.data.map(i => ({
                    value: i.collection_id,
                    label: i.collection_name
                }));

                setOptionTypeProduct([{ value: '', label: '' }, ...data])
            }

        }).catch((e) => {
            console.log("E123 >>>>", e);
        })

    }, []);


    // Số lượng trong kho
    const handleChange = (newValue: string, item: any) => {
            setStockProduct(stockProducts.map(val => {
                if (val.stock_id === item.stock_id) {
                    return {
                        ...item,
                        product_quantity: newValue
                    }
                } else {
                    return val
                }
            }))
        }


    return (
        <LayoutChildrenProduct title={'infomation_general'}>
            <BlockStack gap={'400'}>
                <TextField
                    autoComplete="off"
                    autoFocus
                    label={'Tên sản phẩm'}
                    {...fieldProductName}
                />

                <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 2 }} gap="300">
                    <TextField
                        autoComplete="off"
                        label={'Mã sản phẩm'}
                        {...fieldProductCode}
                    />

                    <TextField
                        label={'Đơn vị tính'}
                        autoComplete="off"
                        {...fieldProductUnit}
                    />
                </InlineGrid>

                <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 2 }} gap="300">
                    <TextField
                        label={'Giá gốc sản phẩm'}
                        suffix="vnđ"
                        maxLength={12}
                        type={'text'}
                        autoComplete="off"
                        value={helpers.formatNumberWithCommas(fieldOriginalPrice.value)}
                        onChange={(a) => fieldOriginalPrice.onChange(a)}
                        error={fieldOriginalPrice.error}
                    />
                    <TextField
                        label={'VAT'}
                        suffix="%"
                        type={'text'}
                        autoComplete="off"
                        value={helpers.formatNumberWithCommas(fieldVat.value)}
                        onChange={(a) => fieldVat.onChange(a)}
                        error={fieldVat.error}
                    />
                </InlineGrid>
                <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 3 }} gap="300">
                    <TextField
                        label={'Hoa hồng sản phẩm B1'}
                        suffix="%"
                        maxLength={12}
                        type={'text'}
                        autoComplete="off"
                        value={helpers.formatNumberWithCommas(fieldProductPriceB1.value)}
                        onChange={(a) => fieldProductPriceB1.onChange(a)}
                        error={fieldProductPriceB1.error}
                    />
                    <TextField
                        label={'Hoa hồng sản phẩm B2'}
                        suffix="%"
                        maxLength={12}
                        type={'text'}
                        autoComplete="off"
                        value={helpers.formatNumberWithCommas(fieldProductPriceB2.value)}
                        onChange={(a) => fieldProductPriceB2.onChange(a)}
                        error={fieldProductPriceB2.error}
                    />
                    <TextField
                        label={'Hoa hồng sản phẩm B3'}
                        suffix="%"
                        maxLength={12}
                        type={'text'}
                        autoComplete="off"
                        value={helpers.formatNumberWithCommas(fieldProductPriceB3.value)}
                        onChange={(a) => fieldProductPriceB3.onChange(a)}
                        error={fieldProductPriceB3.error}
                    />
                </InlineGrid>

                <Layout>
                    <Layout.Section>
                        <LegacyCard title="Chọn kho cho sản phẩm" sectioned>
                            {
                                stockProducts?.map((item, index) => (
                                    <div key={index} style={{ paddingTop: 20 }}>
                                        <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
                                            <span>{item.stock_name}</span>
                                            <TextField
                                                label={''}
                                                suffix="Số lượng"
                                                type="number"
                                                value={item.product_quantity}
                                                autoComplete="off"
                                                onChange={e => handleChange(e, item)}
                                                min={0}
                                            />
                                        </InlineGrid>
                                    </div>
                                ))
                            }
                        </LegacyCard>
                    </Layout.Section>
                </Layout>

                <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 2 }} gap="300">
                    <TextField
                        label={'Chiều dài sản phẩm'}
                        type="number"
                        suffix="cm"
                        autoComplete="off"
                        min={0}
                        {...fieldProductLength}
                    />
                    <TextField
                        label={'Chiều rộng sản phẩm'}
                        type="number"
                        suffix="cm"
                        autoComplete="off"
                        min={0}
                        {...fieldProductWidth}
                    />
                </InlineGrid>

                <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 2 }} gap="300">
                    <TextField
                        label={'Khối lượng sản phẩm'}
                        type="number"
                        suffix="kg"
                        autoComplete="off"
                        min={0}
                        {...fieldProductWeight}
                    />
                    <TextField
                        label={'Chiều cao sản phẩm'}
                        type="number"
                        suffix="cm"
                        autoComplete="off"
                        min={0}
                        {...fieldProductHeight}
                    />
                </InlineGrid>

                <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 3 }} gap="300">
                    <Select
                        label={'Bộ sưu tập sản phẩm'}
                        options={optionTypeProduct}
                        value={fieldCollection.value}
                        onChange={(e) => fieldCollection.onChange(e)}
                        {...fieldCollection}
                    />
                    <Select
                        label={'Danh mục sản phẩm'}
                        options={optionCategories}
                        value={fieldProductCategory.value}
                        onChange={(e) => fieldProductCategory.onChange(e)}
                        {...fieldProductCategory}
                    />
                    <Select
                        label={'Hiển thị'}
                        options={[{
                            value: '',
                            label: ''
                        }
                            , {
                            value: SHOW_PRODUCT.SHOW,
                            label: 'Hiển thị'
                        }, {
                            value: SHOW_PRODUCT.HIDDEN,
                            label: 'Không hiển thị'
                        }]}
                        value={fieldProductStatus.value}
                        onChange={(e) => fieldProductStatus.onChange(e)}
                        {...fieldProductStatus}
                    />
                </InlineGrid>
            </BlockStack>

        </LayoutChildrenProduct>
    )

}

export default memo(InfomationGeneral);

