import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';

import {
  Button,
  Text,
  Toast,
  BlockStack,
  InlineStack,
  Link,
  Badge,
} from "@shopify/polaris";

import {
  GiftCardFilledIcon,
  EmailIcon,
  LocationIcon,
  PhoneIcon,
} from "@shopify/polaris-icons";

import dateandtime from "date-and-time";
import { useAppDispatch, useAppSelector } from "../../config/store";

import default_avatar from "media/images/user-default.svg";
import {
  clearError,
  updateProfile,
  getEntity,
} from "../../store/user.store.reducer";
import helpers from "../../helpers";
import ProfileEditModal from './profile.edit.modal';
import ChangePasswordModal from './profile.change.password.modal';
import StarRating from "components/starRating";
import { IUser } from "interface/user.model";
import AvatarUpload from './components/avatar.upload';
import { updateEntity } from 'store/users.store.reducer';
import { useField, useForm } from '@shopify/react-form';


export default function ProfileHeader({ current_user_id }: { current_user_id: string }) {
  
  
  const isAuthenticated = useAppSelector(state => state.user.isAuthenticated);
  const currentUserData = useAppSelector((state) => state.user.account);
  const entity = useAppSelector((state) => state.user.entity);
  const updateSuccess = useAppSelector((state) => state.user.updateSuccess);
  const [isChangeImg, setIsChangeImg] = useState(false)

  const dispatch = useAppDispatch();

  const [profileData, setProfileData] = useState<IUser>(null);
  useEffect(() => {
    if (currentUserData) {
      setProfileData(currentUserData);
    }
  }, [currentUserData])

  /**
   * Show editor model
   */
  const [showEditModel, setShowEditModel] = useState(false);
  const [showPasswordModel, setShowPasswordModel] = useState(false);

  useEffect(() => {
    dispatch(clearError());
  }, []);

  const fetchUsers = useCallback((user_id: string) => {
    dispatch(getEntity(user_id));
  }, []);

  const getMainQueryDebound = useMemo(() => helpers.debounce(_value => fetchUsers?.call(this, _value), 1000), []);
  useEffect(() => {
    if (current_user_id)
      getMainQueryDebound(current_user_id);
  }, [current_user_id]);

  const [internalErrorNotice, setInternalErrorNotice] = useState("");
  const [srcImageAvatar, setSrcImageAvatar] = useState(default_avatar);

  useEffect(() => {
    setSrcImageAvatar(
      profileData?.user_avatar ?? default_avatar
    );
  }, [profileData]);



  const clearErrorCallback = useCallback(() => {
    dispatch(clearError());
  }, []);

  useEffect(() => {
    clearErrorCallback();
  }, []);


  const successToastMarkup = updateSuccess ? (
    <Toast content={'Cập nhật thành công!'} onDismiss={clearErrorCallback} />
  ) : null;

  const handQuickUpdateSuccess = useCallback((res: any) => {
    setSrcImageAvatar(
      process.env.REACT_APP_AJAX_URL + "/media/download/" + res.media_filename
    );
    dispatch(
      updateProfile({
        user_id: profileData?.user_id,
        user_avatar: `${process.env.REACT_APP_AJAX_URL}/media/download/${res.media_filename}`,
      })
    );
  }, []);

  const handUploadError = useCallback((err: any) => {
    setInternalErrorNotice(err);
  }, []);

  const fullAddress = useRef('Chưa có thông tin');
  const getFullAddress = useCallback(async (profileData) => {
    try {
      if (!profileData?.user_address) return;

      let ward = await helpers.getDiaChinh(profileData?.user_address_ward);
      let distric = await helpers.getDiaChinh(profileData?.user_address_district);
      let city = await helpers.getDiaChinh(profileData?.user_address_city);

      fullAddress.current = `${profileData?.user_address}, ${ward?.name}, ${distric?.name}, ${city?.name}`;
    } catch (e) { }
  }, []);

  useEffect(() => {
    getFullAddress(profileData);
  }, [profileData]);

  const useFields = {
    user_avatar: useField<string[]>({
      value: entity ? [entity?.user_avatar] : [],
      validates: [
      ],
    }),

  }

  const {
    fields,
    submit,
    dirty,
    reset: resetForm,
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
          // update
          await dispatch(
            updateEntity({
              user_id: current_user_id,
              user_avatar: values.user_avatar[0] ?? "",
            })
          ).then(() => {
            setIsChangeImg(false)
          });
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message =
          e?.response?.data?.title ?? "Undefined error. Try again!";
        const field = e?.response?.data?.errorKey ?? "base";
        return { status: "fail", errors: [{ field, message }] };
      }
    },
  });
  /* End form */

  useEffect(() => {
    isChangeImg && submit()
  },[isChangeImg])

  return (
    isAuthenticated && <>
      {successToastMarkup}
      {internalErrorNotice ? (
        <Toast
          content={internalErrorNotice}
          error
          onDismiss={() => setInternalErrorNotice("")}
        />
      ) : null}
      {
        <ProfileEditModal show={showEditModel} account={profileData} onClose={() => setShowEditModel(false)} />
      }

      {
        <ChangePasswordModal show={showPasswordModel} userID={profileData?.user_id} onClose={() => setShowPasswordModel(false)} />
      }

      <div id="profile_heading">
        <div className="profile_inner">
          <InlineStack gap="200" blockAlign="start" align="start">
            <div className="profile-avatar">
              <AvatarUpload
                fieldUrl={fields.user_avatar}
                onError={handUploadError}
                setIsChangeImg={setIsChangeImg}
              />
            </div>
            <div className="profile-description">
              <BlockStack gap="200">
                <Text as="h1" variant="headingLg">
                  {profileData?.display_name
                    ? profileData?.display_name
                    : profileData?.user_email}
                </Text>

                <Text as="p"><Badge tone="success">{profileData?.user_role}</Badge></Text>

                {
                  profileData?.bio ? (
                    <Text as="p">{profileData.bio}</Text>
                  ) : null
                }
                {
                  profileData?.createdAt &&
                  <Text as="p" variant="bodyMd" tone="text-inverse">
                    Tham gia từ ngày{" "}
                    {dateandtime.format(
                      new Date(Number(profileData?.createdAt)),
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                  </Text>
                }
                {
                  profileData?.user_rate > 0 ? (
                    <div>
                      <StarRating num={profileData?.user_rate} />
                      <Text as="span" tone="subdued">{`${profileData?.user_rate_count} đánh giá`}</Text>
                    </div>
                  ) : null
                }

              </BlockStack>
            </div>
          </InlineStack>
        </div>{/** profile_inner // */}


        <br />


        <InlineStack wrap={false} gap={'400'}>
          {
            profileData?.user_birthday ? (
              <Button
                textAlign="left"
                icon={GiftCardFilledIcon}
                variant="plain"
              >
                {dateandtime.format(
                  new Date(profileData?.user_birthday),
                  "DD/MM/YYYY"
                ) || "Your birthday"}
              </Button>
            ) : null
          }

          {
            profileData?.user_email ? (
              <Button
                textAlign="left"
                icon={EmailIcon}
                variant="plain"
              >
                {profileData?.user_email}
              </Button>
            ) : null
          }

          {
            profileData?.user_phonenumber ? (
              <Button
                textAlign="left"
                icon={PhoneIcon}
                variant="plain"
              >
                {profileData?.user_phonenumber}
              </Button>
            ) : null
          }


        </InlineStack>

        <br />
        {
          profileData?.user_address ? (
            <Button
              textAlign="left"
              icon={LocationIcon}
              variant="plain"
            >
              {fullAddress.current}
            </Button>
          ) : null
        }


        {
          currentUserData?.user_id === profileData?.user_id ? (
            <>
              <br />
              <br />
              <InlineStack wrap={false} gap={'400'}>
                <Link monochrome onClick={() => setShowEditModel(true)}>Chỉnh sửa thông tin</Link>
                <Link monochrome onClick={() => setShowPasswordModel(true)}>Đổi mật khẩu</Link>
              </InlineStack>
            </>
          ) : null
        }

      </div> {/** profile_heading */}
    </>
  )
}