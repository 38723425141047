import {
  DataTable,
  EmptyState,
  Layout,
  Page,
  Card,
  Badge,
  Popover,
  Icon,
  Thumbnail
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/store";
import emptyIMG from "../../media/images/empty.png";
import Pagination from "../../components/pagination";
import helpers from "../../helpers";
import __ from "languages/index";

import { NoteIcon, ChevronDownIcon } from '@shopify/polaris-icons'
import dateandtime from "date-and-time";
import { assignUserToGroup, deleteUserInGroup, getEntitiesUnapproved } from "store/group_user.store.reducer";
import { MEMBER_GROUP_STATUS } from "constant";
import ImgsViewer from 'react-images-viewer';

export default function UserInGroupList() {
  const entities = useAppSelector((state) => state.group_user.membersUnapproved);
  const totalItems = useAppSelector((state) => state.group_user.totalItemMemebersUnapproved);
  const dispatch = useAppDispatch();
  const [showPopupForChangeUserStatus, setShowPopupForChangeUserStatus] = useState('');
  const [currImg, setCurrImg] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [imgViewerUrl, setImgViewerUrl] = useState('');
  
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let useParam = {} as any;
  useParam = useLocation();
  let StringQuery: any = helpers.ExtractUrl(useParam.search) || false;

  const initialQuery = {
    query: "",
    page: 1,
    limit: 20,
    sort: "createdAt: desc",
    user_role: ''
  }
  const [mainQuery, setMainQuery] = useState({
    ...StringQuery,
    ...initialQuery
  });

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({ ...mainQuery, page: numPage, limit: limit });
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  let m = mainQuery;
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      m = initialQuery;
      return setMainQuery(initialQuery);
    } else {
      m = Object.assign({}, m, _value);
      setMainQuery(m);
    }
  }, []);



  /**
   * New model
   */

  useEffect(() => {
    dispatch(getEntitiesUnapproved({ entity: mainQuery }));
  }, [mainQuery]);

  const emptyData = (
    <EmptyState heading="Chưa có thành viên trong nhóm này!" image={emptyIMG}>
    </EmptyState>
  );

  const handleSort = useCallback(
    (index, direction) => {
      let _direction = direction === "descending" ? "desc" : "asc";
      let sort = "";
      switch (index) {
        case 0:
          sort = "franchise_branch_name:";
          break;
        case 1:
          sort = "franchise_branch_address:";
          break;
        case 2:
          sort = "franchise_branch_owner:";
          break;
        case 3:
          sort = "franchise_branch_contact_info:";
          break;
        case 4:
          sort = "franchise_branch_status:";
          break;
        case 5:
          sort = "franchise_branch_sign_date:";
          break;

        default:
          break;
      }

      if (sort.length) {
        entities.sort((a, b) => {
          if (_direction == 'desc') {
            return a[sort] - b[sort]
          }
          return b[sort] - a[sort]
        })
      }

    },
    [entities]
  );

  const handleDeleteItem = (_id: string) => {
    if(window.confirm('Bạn có chắc chắn muốn xoá không ?')){
      dispatch(deleteUserInGroup({ user_group_id: _id }))
        .unwrap()
        .then(res => {
          dispatch(getEntitiesUnapproved({entity: mainQuery }));
        })
    }
  }

  
  // open viewer image
  const handleOnViewer = (url) => {
    setImgViewerUrl(url)
    setIsOpen(true)
  }

  const convertMemberGroupStatus = (value) => {
    switch (value) {
      case MEMBER_GROUP_STATUS
      .IN_ACTIVE:
        return <Badge tone="critical-strong">Chờ duyệt</Badge>
        break;
      case MEMBER_GROUP_STATUS
      .ACTIVE:
        return <Badge tone="success">Đã duyệt</Badge>
        break;
      default:
        break;
    }
  }

  const renderItem = (item, index) => {
    const {
      user: {
        display_name,
        user_email,
        user_phonenumber,
        user_address,
      },
      group_id,
      user_group: {
        group_description,
        group_name
      },
      approved,
      user_id,
      createdAt,
      user_to_group_id,
      payment_witness
    } = item;

    const branch_name = display_name || 'noname';
    const sign_date = dateandtime.format(new Date(+createdAt), 'DD/MM/YYYY')

    return [
      index + 1,
      branch_name,
      user_phonenumber,
      user_email,
      <span>{group_name}</span>,
     <div style={{ cursor: payment_witness ? 'pointer' : '' }} onClick={payment_witness ? () => handleOnViewer(payment_witness) : () => {}}><Thumbnail size="small" source={payment_witness ? payment_witness : NoteIcon} alt="" /></div>,
      +approved === MEMBER_GROUP_STATUS.IN_ACTIVE ?
      <Popover
          active={showPopupForChangeUserStatus === user_to_group_id}
          activator={<>
            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => setShowPopupForChangeUserStatus(user_to_group_id)}>
                {
                  convertMemberGroupStatus(approved)
                }
                <Icon
                  source={ChevronDownIcon}
                  tone="base"
                />
            </div>
          </>}
          autofocusTarget="first-node"
          onClose={() => setShowPopupForChangeUserStatus('')}
        >
          <div style={{ padding: 10, display: 'flex', flexDirection: 'column' }}>
            { +approved === MEMBER_GROUP_STATUS.IN_ACTIVE &&
              <div style={{cursor: 'pointer' }} onClick={() => handleAssignMemberToGroup(user_id, group_id)}><Badge tone="success-strong">Phê duyệt</Badge></div>
            }
          </div>
        </Popover> : 
          convertMemberGroupStatus(approved)
      ,
      sign_date,
      // <InlineStack gap={"200"} blockAlign="center">
      //  <Tooltip content='Xoá người dùng ra khỏi nhóm'>
      //   <Button variant="tertiary" onClick={() => handleDeleteItem(user_to_group_id)} icon={DeleteIcon} />
      //  </Tooltip>
      // </InlineStack>
    ];
  };

  const FranchiseBranchList = (
    <>
      {
        entities.length > 0 ? (
          <DataTable
            sortable={[
              false,
              false,
              false,
              false,
              false,
              false,
              false,
            ]}
            defaultSortDirection="descending"
            initialSortColumnIndex={0}
            onSort={handleSort}
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
            ]}
            headings={[
              "STT",
              __('user_full_name'),
              __('general_agent_phone'),
              __('franchise_branch_email'),
              'Cấp hệ thống',
              'Bằng chứng thanh toán',
              'Trạng thái',
              __('franchise_branch_sign_date'),
            ]}
            rows={entities?.map(renderItem)}
            hideScrollIndicator
            footerContent={`Display page ${mainQuery.page} of total ${totalItems} results...`}
          />
        ) : (emptyData)
      }
    </>
  );

  const Actual_page = (
    <>
      <Page
        fullWidth
        title={`Danh sách chờ duyệt`}
      >
        <Layout>
          <Layout.Section>
            <Card padding={'0'}>
              {FranchiseBranchList}
            </Card>
            <br />
            {totalItems > mainQuery.limit ? (
              <Pagination
                TotalRecord={totalItems}
                activeCurrentPage={mainQuery.page}
                pageSize={mainQuery.limit}
                onChange={onChangePagination}
              />
            ) : null}
          </Layout.Section>
        </Layout>
      </Page>

    </>
  );

  const handleAssignMemberToGroup = (_id: string, group_id: string) => {
    dispatch(assignUserToGroup({
      group_id: group_id,
      user_id: _id
    })).unwrap().then((res) => {
      dispatch(getEntitiesUnapproved({entity: mainQuery }));
    })
  }

  return (
    <>
      {Actual_page}
      <ImgsViewer
        imgs={[
          {
            src: imgViewerUrl
          }
        ]}
        class={'test'}
        currImg={currImg}
        showThumbnails={false}
        isOpen={isOpen}
        onClickPrev={e => setCurrImg(currImg - 1)}
        onClickNext={e => setCurrImg(currImg + 1)}
        onClickThumbnail={index => setCurrImg(index)}
        onClose={e => setIsOpen(false)}
        showImgCount={false}
      />
    </>
  );
}
