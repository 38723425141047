import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { DEFAULT_LOGISTIC_SERVICE } from 'enum/logistic.enum';


export interface TypedLogistic_service {
    logistic_id?: string; 
	logistic_name?: string; 
	module?: string; 
	logistic_description?: string; 
	logistic_count?: number; 
	logistic_status?: number; 
  logistic_delivery_method?: string;
	createdAt?: string; 
  is_default_service?: DEFAULT_LOGISTIC_SERVICE;
}

/**
*   Reducer used for front-end, with comment.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using comment.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: null as any,
  entities: [] as TypedLogistic_service[],
  entity: null as TypedLogistic_service,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'v2/logistic_service';

// Actions

export const getEntities = createAsyncThunk('logistic_service/fetch_entity_list', async (object: any) => {
    const EndURL = helpers.buildEndUrl(object);
    const requestUrl = `${apiUrl}${EndURL}`;
    return axios.get<any>(requestUrl);
    }, 
    { serializeError: serializeAxiosError }
);


export const createEntity = createAsyncThunk(
    'logistic_service/create_entity',
    async (entity: TypedLogistic_service, thunkAPI) => {
      return await axios.post<TypedLogistic_service>(`${apiUrl}`, helpers.cleanEntity(entity));
    },
    { serializeError: serializeAxiosError }
  );

export const updateEntity = createAsyncThunk(
    'logistic_service/update_entity',
    async (entity: TypedLogistic_service, thunkAPI) => {
      // patch
      const logistic_id = String( entity.logistic_id );
      delete entity.logistic_id;
      return await axios.patch<TypedLogistic_service>(`${apiUrl}/${logistic_id}`, helpers.cleanEntity(entity));
    },
    { serializeError: serializeAxiosError }
  );


export const getEntity = createAsyncThunk(
    'logistic_service/fetch_entity',
    async (id: string | number) => {
      const requestUrl = `${apiUrl}/${id}`;
      return axios.get<any>(requestUrl);
    },
    { serializeError: serializeAxiosError }
  );

// slice

export const Reducer_logistic_service = createEntitySlice({
  name: 'logistic_service',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
      state.updateSuccess = null;
    }
  },
  extraReducers(builder) {
    builder
    
    .addCase(createEntity.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addCase(createEntity.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.entity = null;
        state.errorMessage = action.payload
      })
      .addCase(createEntity.pending, (state) => {
        state.updating = true;
        state.updateSuccess = false;
        state.entity = null;
      })
    
    .addCase(updateEntity.fulfilled, (state, action) => {
        state.updating = false;
        state.entity = action.payload.data;
      })
      .addCase(updateEntity.rejected, (state, action) => {
        state.updating = false;
        state.entity = null;
        state.errorMessage = action.payload
      })
      .addCase(updateEntity.pending, (state) => {
        state.updating = true;
        state.entity = null;
      })
    

    .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
        state.errorMessage = action.payload
      })
      .addCase(getEntity.pending, (state) => {
        state.loading = true;
        state.entity = null;
      })


      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(getEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.totalItems = 0;
        state.loading = true;
      })
  },
});

export const { clearError, reset } = Reducer_logistic_service.actions;

// Reducer
export default Reducer_logistic_service.reducer;
