import { Page, Toast, Layout, FormLayout, TextField, Form, SelectOption, Select } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "config/store";
import {
  clearError,
  getEntity,
  updateEntity,
  createEntity,
} from "store/stock.store.reducer";
import SkeletonLoading from 'components/skeletonLoading';
import { useField, useForm } from '@shopify/react-form';
import 'media/css/product.scss'
import LayoutChildrenProduct from "layout/layout.infomation.product";
import __ from "languages/index";
import { InlineGrid } from '@shopify/polaris';
import ModalConfirm from 'components/modalConfirm';
import { TypeAddess, TypeDistrict, TypeWard } from 'interface/stoke.model';
import addressData from 'media/diachinh.json';
import { convertData } from 'utils';

export default function StockCreate() {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.stock_id || false;

  /**
   * nếu không có ID, xóa mọe đi
   */
  if (isNaN(Param)) {
    Param = false;
  }


  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const entity = useAppSelector((state) => state.stock.entity);
  const loading = useAppSelector((state) => state.stock.loading);
  const updating = useAppSelector((state) => state.stock.updating);
  const updateSuccess = useAppSelector((state) => state.stock.updateSuccess);
  const [showModal, setShowModal] = useState(false);
  const [internalErrorNotice, setInternalErrorNotice] = useState("");
  const [addresses, setAddresses] = useState<TypeAddess[]>([]);
  const [codeCity, setCodeCity] = useState<string>('');
  const [codeDistrict, setCodeDistrict] = useState<string>('');
  const [districtByCity, setDistrictByCity] = useState<TypeDistrict[]>([]);
  const [wardByDistrict, setWardByDistrict] = useState<TypeWard[]>([]);
  const [optionStockAddressCity, setOptionStockAddressCity] = useState<SelectOption[]>([]);
  const [optionDistrict, setOptionDistrict] = useState<SelectOption[]>([]);
  const [optionWard, setOptionWard] = useState<SelectOption[]>([]);
  const account = useAppSelector(state => state.user.account);

  const clearErrorCallback = useCallback(() => {
    dispatch(clearError());
  }, []);

  useEffect(() => {
    clearErrorCallback();
    setAddresses(convertData(addressData));
  }, []);

  useEffect(()=> {
    if(entity){
      setCodeCity(entity?.stock_address_city)
      setCodeDistrict(entity?.stock_address_district)
    }
  },[entity])

  useEffect(() => {
    const district = addresses?.filter(item => item?.code === codeCity).map(item => item['quan-huyen'])
    setDistrictByCity(convertData(district[0]))
  }, [addresses, codeCity]);


  useEffect(()=>{
    const ward = districtByCity?.filter(item => item?.code === codeDistrict).map(item => item['xa-phuong'])
    if(!!ward){
      setWardByDistrict(convertData(ward[0]))
    }
  },[addresses, codeDistrict, districtByCity])

  useEffect(() => {
    let optionStockAddressCity = [];
    let optionDistrict = [];
    let optionWard = [];

    optionStockAddressCity = addresses?.map(item => {
      return { value: item.code, label: item.name }
    });
    optionDistrict = districtByCity?.map(item => {
      return { value: item.code, label: item.name }
    });
    optionWard = wardByDistrict?.map(item => {
      return { value: item.code, label: item.name }
    });
    optionStockAddressCity && setOptionStockAddressCity([{ value: '', label: '' }, ...optionStockAddressCity]);
    optionDistrict && setOptionDistrict([{ value: '', label: '' }, ...optionDistrict]);
    optionWard && setOptionWard([{ value: '', label: '' }, ...optionWard]);
  }, [addresses, districtByCity, wardByDistrict])

  useEffect(()=> {
    updateSuccess && navigate('/stock/list')
  },[updateSuccess])

  const successNotice = updateSuccess ? (
    <Toast content={!Param ? 'Thêm kho thành công' :'Cập nhật thành công!'} onDismiss={clearErrorCallback} />
  ) : null;

  const useFields = {
    stock_name: useField<string>({
      value: (entity && Param) ? entity?.stock_name : '',
      validates: [
        (inputValue) => {
          if (!inputValue) {
            return __('require')
          };
        }
      ],
    }),
    stock_code: useField<string>({
      value: (entity && Param) ? entity?.stock_code : '',
      validates: [
        (inputValue) => {
          if (!inputValue) return __('require');
        }
      ],
    }),
    manager: useField<string>({
      value: (entity && Param) ? entity?.manager : '',
      validates: [
        (inputValue) => {
          if (!inputValue) return __('require');
        }
      ],
    }),
    hotline: useField<string>({
      value: (entity && Param) ? entity?.hotline : '',
      validates: [
        (inputValue) => {
          if (!inputValue) return __('require');
        }
      ],
    }),
    stock_address_city: useField<any>({
      value: (entity && Param) ? addresses?.find((item)=>item?.code === entity?.stock_address_city)?.code : '',
      validates: [
        (inputValue) => {
          if (!inputValue) return __('require');
        }
      ],
    }),
    stock_address_district: useField<string>({
      value: (entity && Param) ? districtByCity?.find((item)=>item?.code === entity?.stock_address_district)?.code : '',
      validates: [
        (inputValue) => {
          if (!inputValue) return __('require');
        }
      ],
    }),
    stock_address_ward: useField<string>({
      value: (entity && Param) ? wardByDistrict?.find((item)=>item?.code === entity?.stock_address_ward)?.code : '',
      validates: [
        (inputValue) => {
          if (!inputValue) return __('require');
        }
      ],
    }),
    stock_address: useField<string>({
      value: (entity && Param) ? entity?.stock_address : '',
      validates: [
        (inputValue) => {
          if (!inputValue) return __('require');
        }
      ],
    }),
  };


  const {
    fields,
    submit,
    dirty,
    reset: stockReset,
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      console.log("stock", values);

      try {
        if (!Param) {
          // create new 
          dispatch(createEntity({
            ...values
          }))
        } else {
          dispatch(updateEntity({
            stock_id: entity.stock_id,
            ...values
          }))
        }
        return { status: 'success' };
      } catch (e: any) {
        console.error(`Submit error`, e);
        const message = e?.response?.data?.title ?? 'Lỗi không xác định, vui lòng thử lại sau.';
        const field = e?.response?.data?.errorKey ?? 'base';
        return { status: 'fail', errors: [{ field, message }] };
      }
    },
  });

  // const handQuickUpdateSuccess = useCallback((res: any) => {
  //   useFields.product_avatar.onChange(
  //     process.env.REACT_APP_AJAX_URL + "/media/download/" + res.media_filename
  //   );
  // }, []);

  const handUploadError = useCallback((err: any) => {
    setInternalErrorNotice(err);
  }, []);

  useEffect(() => {
    fields.stock_address_city.value && setCodeCity(fields.stock_address_city.value)
  }, [fields.stock_address_city.value])


  useEffect(() => {
    fields.stock_address_district.value && setCodeDistrict(fields.stock_address_district.value)
  }, [fields.stock_address_district.value])

  useEffect(() => {
    Param ? dispatch(getEntity(Param)) : stockReset();
  }, [Param]);

  return (
    <>
      {successNotice}

      {internalErrorNotice ? (
        <Toast
          content={internalErrorNotice}
          error
          onDismiss={() => setInternalErrorNotice("")}
        />
      ) : null}

      {(loading || updating) ? <SkeletonLoading /> :
        <Page
          fullWidth
          backAction={{
            content: 'Stock',
            url: '/stock'
          }}
          title={Param ? `Chỉnh sửa kho` : 'Thêm kho mới'}
          subtitle={''}
          primaryAction={(['admin', 'stock_manager'].includes(account?.user_role)) && { content: Param ? 'Cập nhật' : 'Lưu thông tin kho', disabled: !dirty, loading: updating, onAction: submit }}
          // secondaryActions={[
          //   {
          //     content: 'Đặt lại tất cả',
          //     accessibilityLabel: 'Secondary action label',
          //     onAction: () => setShowModal(true),
          //     icon: ReturnIcon
          //   }
          // ]}
        >
          <Layout >
            <Layout.Section>
              <Form onSubmit={submit}>
                <FormLayout>
                  <InlineGrid gap="300" columns={{ xs: '1', sm: '1', md: '1', lg: '1' }}>
                    <LayoutChildrenProduct title={'infomation_general'}>
                      <TextField
                        autoComplete="off"
                        autoFocus
                        label={'Tên kho hàng'}
                        {...fields.stock_name}
                      />
                      <br/>
                      <TextField
                        autoComplete="off"
                        autoFocus
                        label={'Mã kho hàng'}
                        {...fields.stock_code}
                      />
                      <br/>
                      <InlineGrid gap="300" columns={{ xs: '1', sm: '1', md: '2', lg: '2' }}>
                        <TextField
                          autoComplete="off"
                          autoFocus
                          label={'Tên quản lý kho hàng'}
                          {...fields.manager}
                        />
                        <TextField
                          type="number"
                          autoComplete="off"
                          autoFocus
                          label={'Số điện thoại kho hàng'}
                          {...fields.hotline}
                        />
                      </InlineGrid>
                      <br/>
                      <InlineGrid gap="300" columns={{ xs: '1', sm: '1', md: '3', lg: '3' }}>
                        <Select
                          label={'Tỉnh / thành phố'}
                          options={optionStockAddressCity}
                          value={fields.stock_address_city.value}
                          onChange={(e) => fields.stock_address_city.onChange(e)}
                          {...fields.stock_address_city}
                        />
                        <Select
                          label={'Quận / huyện'}
                          options={optionDistrict}
                          value={fields.stock_address_district.value}
                          onChange={(e) => fields.stock_address_district.onChange(e)}
                          {...fields.stock_address_district}
                        />
                        <Select
                          label={'Phường / xã'}
                          options={optionWard}
                          value={fields.stock_address_ward.value}
                          onChange={(e) => fields.stock_address_ward.onChange(e)}
                          {...fields.stock_address_ward}
                        />
                      </InlineGrid>
                      <br/>
                      <TextField
                        autoComplete="off"
                        autoFocus
                        label={'Số nhà , tên đường...'}
                        {...fields.stock_address}
                      />
                    </LayoutChildrenProduct>
                  </InlineGrid>
                </FormLayout>
              </Form>
            </Layout.Section>
          </Layout>
          <ModalConfirm
            active={showModal}
            setActive={setShowModal}
            onAction={() => {
              stockReset();
              setShowModal(false);
            }}
            title="Bạn đang thao tác đặt lại tất cả nội dung nhập kho"
            btnText="Đặt lại tất cả"
            contentText="Bạn sẽ phải bắt đầu nhập lại thông tin từ đầu"
          />
        </Page >
      }
    </>
  );
}