import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { createEntitySlice, serializeAxiosError } from '../config/reducer.utils';
import { TypedStock } from 'interface/stock.model';


/**
*   Reducer used for front-end, with customer.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using customer.model.ts
*/

const initialState = {
  loading: false,
  errorMessage: null as any,
  entities: [] as TypedStock[],
  userEntities: null as TypedStock[],
  loadingUserEntities: false,
  entity: {} as TypedStock,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'v2/stock';

// Actions

export const getEntities = createAsyncThunk('order_stock/fetch_entity_list', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return axios.get<TypedStock[]>(requestUrl);
});

export const reloadEntities = createAsyncThunk('order_stock/reloadEntities', async (object: any) => {
  const EndURL = helpers.buildEndUrl(object);
  const requestUrl = `${apiUrl}${EndURL}`;
  return axios.get<any>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'order_stock/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<TypedStock>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);


// slice

export const Reducer_order_stock = createEntitySlice({
  name: 'order_stock',
  initialState,
  reducers: {
    clearError: (state) => {
      state.errorMessage = null;
      state.updateSuccess = false;
    },
    clearEntity: (state) => {
      state.entity = null
    },
    reset: (state) => {
      return {...state, ...initialState};
    },
    resetUserEntities: (state) => {
      state.userEntities = null;
    }
  },
  extraReducers(builder) {
    builder
    
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntity.rejected, (state, action) => {
        state.loading = false;
        state.entity = null;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(reloadEntities), (state, action) => {
        return {
          ...state,
          entities: action.payload.data,
          totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(getEntity), state => {
        state.entity = null;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })

  },
});

export const { clearError, clearEntity, reset, resetUserEntities } = Reducer_order_stock.actions;

// Reducer
export default Reducer_order_stock.reducer;
